import React, { useState } from "react";
import ArrowIcon from "../../assets/Group.png";
import weekimg from "../../assets/week list.png";
import leftarrow from "../../assets/Group 23.png";
import rightarrow from "../../assets/Group 26.png";
import { array } from "yup";
import "./weekTimeline.css";
import useScreenSize from "../helper/UserScreenSize";

const FrontEndWeekTimeLine = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const width = useScreenSize()
  const weekList = [
    {
      value: 1,
      title: "Week 1",
      session1: "Introduction to Front-End Development",
      session2: "Overview of Front-End Development",
      session3: "Role of a Front-End Developer",
      session4: "HTML Fundamentals",

    },
    {
      value: 2,
      title: "Week 2",
      session1: "Advanced HTML",
      session2: "Semantic HTML",
      session3: "HTML Forms and Input Elements",
      session4: "Accessibility in Web Development"
    },
    {
      value: 3,
      title: "Week 3",
      session1: "CSS Fundamentals",
      session2: "Introduction to Cascading Style Sheets",
      session3: "Selectors and Specificity",
      session4: "Basic Text Styling"
    },

    {
      value: 4,
      title: "Week 4",
      session1: "CSS Layout Techniques",
      session2: "Box Model and Margins/Padding",
      session3: "Display Properties (Block, Inline, Inline-Block)",
      session4: "CSS Flexbox for Layout",
    },
    {
      value: 5,
      title: "Week 5",
      session1: "Responsive Web Design",
      session2: "Media Queries",
      session3: "Fluid Layouts",
      session4: "Mobile-First Approach"
    },
    {
      value: 6,
      title: "Week 6",
      session1: "CSS Grid Layout",
      session2: "Creating Grid Layouts",
      session3: "Responsive Grids",
      session4: "Grid vs. Flexbox"
    },
    {
      value: 7,
      title: "Week 7",
      session1: "Introduction to JavaScript",
      session2: "JavaScript Fundamentals",
      session3: "Variables and Data Types",
      session4: "Functions and Control Structures"
    },
    {
      value: 8,
      title: "Week 8",
      session1: "Document Object Model (DOM)",
      session2: "Selecting and Modifying Elements",
      session3: "Event Handling",
    },
    {
      value: 9,
      title: "Week 9",
      session1: "JavaScript Functions and Objects",
      session2: "Functions and Scopes",
      session3: "Object-Oriented JavaScript",
      session4: "Error Handling and Debugging"
    },
    {
      value: 10,
      title: "Week 10",
      session1: "Asynchronous JavaScript",
      session2: "Callbacks and Promises",
      session3: "Fetch API and AJAX",
      session4: "Handling API Data"
    },
    {
      value: 11,
      title: "Week 11",
      session1: "Introduction to Front-End Frameworks",
      session2: "Overview of Popular Front-End Frameworks (e.g., React, Vue, Angular)",
      session3: "Setting up a React Development Environment",
    },
    {
      value: 12,
      title: "Week 12",
      session1: "React Basics",
      session2: "React Components and Props",
      session3: "Managing State and Lifecycle",
      session4: "React Router for Navigation"
    },
    {
      value: 13,
      title: "Week 13",
      session1: "Advanced React",
      session2: "React Hooks",
      session3: "Context API for State Management",
      session4: "Routing and Navigation in React",
    },
    {
      value: 14,
      title: "Week 14",
      session1: "State Management with Redux",
      session2: "Redux Principles",
      session3: "Actions and Reducers",
      session4: "Integrating Redux with React",

    },
    {
      value: 15,
      title: "Week 15",
      session1: "Consuming RESTful APIs",
      session2: "Fetching Data from APIs",
      session3: "Handling API Responses",
      session4: "Axios for HTTP Requests",

    },
    {
      value: 16,
      title: "Week 16",
      session1: "Front-End Project Development",
      session2: "Individual or Group Front-End Projects",
    },
  ];

  return (
    <>
      <div className=" d-flex justify-content-center mt-5 mx-auto mt-5 pt-5">
        <div className="col-lg-10 col-12 mt-5" style={{overflowX: width < 768 && "scroll"}}>
          <div className={`row g-0`} style={{width: width < 768 && "700px", overflowX: width < 768 && "scroll"}}>
            <div className="col-lg-3 col-12 px-4 time_line border_radus_7px">
              <div className="p-2 fs_30 DM_Serif_Text black_color pb-4 text-nowrap">Time Line</div>
              <div className="custom-container d-lg-block d-none">
                {weekList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex open_sans fs_18 fw-bold mt-5 cursor_pointer text-nowrap"
                        onClick={() => setCurrentWeek(item?.value)}
                      >
                        {item?.value === currentWeek ? (
                          <>
                            <img className="" src={ArrowIcon} height={"20px"} width={"20px"} />
                          </>
                        ) : null}
                        <span className="ps-3">{item?.title}</span>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="horizontal-container d-lg-none d-block">
                <div className="d-flex gap-5">
                  {weekList?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="open_sans fs_15 mt-5 cursor_pointer text-nowrap"
                          onClick={() => setCurrentWeek(item?.value)}
                        >
                          <button className={`${item?.value === currentWeek ? "redbtn px-3 p-2" : "nobtn px-3 p-2"}`}>
                            <span>{item?.title}</span>
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 px-3 mt-5 mt-lg-0">
              <div className=" d-flex justify-content-between ms-lg-5">
                <div className="p-2 fs_xs_24 fs_32 DM_Serif_Text black_color">Week {currentWeek}</div>
                <div className="d-lg-none">
                  <div className="d-flex justify-content-end align-items-center pe-2 mt-4">
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre - 1)}
                      onClick={() => {
                        if (currentWeek > 1) {
                          setCurrentWeek((pre) => pre - 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek < 16) {
                            horizontalContainer.scrollLeft -= 100;
                          }
                        }
                      }}

                      className="scrollbtns" disabled={currentWeek === 1}>
                      <img className=" w-50" src={leftarrow} />
                    </button>
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre + 1)} 
                      onClick={() => {
                        if (currentWeek < 16) {
                          setCurrentWeek((pre) => pre + 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek >= 1) {
                            horizontalContainer.scrollLeft += 100;
                          }
                        }
                      }}
                      className="scrollbtns" disabled={currentWeek === 16}>
                      <img className=" w-50" src={rightarrow} />
                    </button>
                  </div>
                </div>
              </div>
              <div>
              {weekList
                ?.filter((item) => item?.value === currentWeek)
                .map((data) => {
                  return (
                      <div className=" position-relative ms-lg-5">
                        <div className="mt-3">
                          <img className="d-none d-md-block w-100 weekimg" height="500px" src={weekimg} />
                          <img className="d-block d-sm-none w-100 weekimg" height="350px" src={weekimg} />
                          <img className="d-none d-sm-block d-md-none w-100 weekimg" height="400px" src={weekimg} />
                        </div>

                        {
                              <div className=" text-center">
                                {data.session1 && <div className="session_11 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session1}
                                </div>}
                                {data?.session2 && <div className="session_22 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session2}
                                </div>}
                                {data?.session3 && <div className="session_33 fs_sm_10 fs_14 open_sans px-3 fw_600 me-3 me-sm-0">
                                  {data.session3}
                                </div>}
                                {data.session4 && <div className="session_44 fs_sm_10 fs_14 open_sans px-3 fw_600 me-1 me-sm-0">
                                  {data.session4}
                                </div>}
                              </div>
                        }
                      </div>
                  );
                })}
              </div>
              
              <div className="d-none d-lg-block">
                <div className="d-flex justify-content-end pe-2 mt-5 gap-2">
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre - 1)}
                    onClick={() => {
                      if (currentWeek > 1) {
                        setCurrentWeek((pre) => pre - 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek <= 10) {
                          customContainer.scrollTop -= 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 1}>
                    <img className=" w-75" src={leftarrow} />
                  </button>
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre + 1)}
                    onClick={() => {
                      if (currentWeek < 16) {
                        setCurrentWeek((pre) => pre + 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek >= 7) {
                          customContainer.scrollTop += 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 16}>
                    <img className=" w-75" src={rightarrow} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontEndWeekTimeLine;
