import React from 'react'

const EmbarkLearning = () => {
  return (
    <div className="mt-md-5 py-5">
      <div className='mt-md-5 pt-md-5 d-flex justify-content-center'>
        <div className='col-lg-5 col-11'>
          <div className='titlepayment d-flex justify-content-center mt-5 DM_Serif_Text text-center'>Prepare to Embark: Path, Timeframe & Practical Learning</div>
          <div className=' d-flex justify-content-center align-content-center text-center '>
            <div className='internship mt-3 open_sans'>Embark on a Learning Odyssey: Unravel Pathways, Embrace Timeframes, and Cultivate Real-world Expertise </div>
          </div>
          <div className=' pt-5'>
            <div className=' d-flex justify-content-center align-items-center'>
              <div className='eligibility px-5'>
                <div className='open_sans eligibilities'>Eligibility</div>
                <div className='DM_Serif_Text eliggraduate'>Any Graduates</div>
              </div>
            </div>
            <div className=' d-flex justify-content-center align-items-center mt-4'>
              <div className='eligibility px-5'>
                <div className='open_sans eligibilities'>Course Duration</div>
                <div className='DM_Serif_Text eliggraduate'>16 Weeks / 4 Months</div>
              </div>
            </div>
            {/* <div className=' d-flex justify-content-center align-items-center mt-4'>
              <div className='eligibg px-5' style={{ transform: 'rotate(-3deg)' }}>
                <div className='DM_Serif_Text studynows'>"Study Now, Pay After Getting Placed"</div>
              </div>
            </div> */}
            <div className=' d-flex justify-content-center align-items-center mt-4'>
              <div className='eligibility px-5'>
                <div className='DM_Serif_Text eliggraduate'>Project-Based Live Learning</div>
                <div className='open_sans listitems mt-4 ps-3 d-flex gap-2'>
                  <span className='dots'></span>
                  <span className='pt-1'>Number of Projects During the Course: <span className='fontbold'>16</span></span>
                </div>
                <div className='open_sans listitems ps-3 d-flex gap-2'>
                  <span className='dots'></span>
                  <span className='pt-1'><span className=''>No of Interviews: </span> <span className='fw_600'>16 to help to crack job interviews</span></span>
                </div>
                <div className='open_sans listitems ps-3 d-flex gap-2'>
                  <span className='dots'></span>
                  <span className='pt-1'><span className=''>Learning format: </span> <span className='fw_600'>Offline/Online</span></span>
                </div>
                <div className='open_sans listitems ps-3 d-flex gap-2'>
                  <span className='dots'></span>
                  <span className='pt-1'><span className='fw_600'>Resume Building Session</span></span>
                </div>
              </div>
            </div>
       
        </div>
      </div>
    </div>
    </div >
  )
}

export default EmbarkLearning