import React, { useEffect, useState } from "react";
import diplomaicon from "../../assets/diploma 1.png";
import clipboardicon from "../../assets/clipboard-user 1.png";
import briefcaseicon from "../../assets/briefcase (2) 1.png";
import { useLocation } from "react-router";

const WhatMore = () => {
  const [activetab, setActivetab] = useState("");
  const router = useLocation();
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else if (router.pathname.startsWith("/front-end-development-course")) {
      return "front_end";
    } else if (router.pathname.startsWith("/back-end-development-course")) {
      return "back_end";
    } else if (router.pathname.startsWith("/mobile-development-course")) {
      return "mobile";
    } else if (router.pathname.startsWith("/work-shop")) {
      return "work_shop";
    } else if (router.pathname.startsWith("/")) {
      return "home";
    } else if (router.pathname.startsWith("")) {
      return "contact_us";
    }
    // else{
    //   return "contact_us"
    // }
  };

  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActivetab(newActiveTab);
  }, [router.pathname]);
  return (
    <div className="mt-5 py-5">
      <div className=" d-flex justify-content-center">
        <div className="col-lg-5 col-11 ps-lg-5px-2 px-lg-0 ">
          <div className="titlepayment d-flex justify-content-center mt-lg-5 DM_Serif_Text">
            What More
          </div>
          <div className=" d-flex justify-content-center align-content-center text-center">
            <div className="internship mt-3 open_sans">
              {activetab === "full_stack"
                ? "Explore more about Almnd's courses and strategy for your successful career here."
                : activetab === "front_end"
                ? "List of frequently asked questions to provide you with comprehensive answers. Explore the information below to gain deeper insights into Almnd's offerings."
                : "Explore more about Almnd's courses and strategy for your successful career here."
              }
            </div>
          </div>
          <div className="">
            <div className=" d-flex align-items-center gap-5 mt-5 pt-5">
              <div>
                <img width="60px" src={diplomaicon} />
              </div>
              <div className="subtitless open_sans">What about Internship?</div>
            </div>
            <div className="open_sans color_969696 fs_sm_15 fs_16 mt-4">
              We believe that hands-on experience is the key to bridging the gap
              between theory and real-world application. Our internship program
              offers you the chance to immerse yourself in a professional
              setting, applying the skills you've acquired in a practical
              environment.
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className=" d-flex align-items-center gap-5">
              <div>
                <img width="60px" src={clipboardicon} />
              </div>
              <div className="subtitless open_sans">What about Portfolio?</div>
            </div>
            <div className="open_sans color_969696 fs_sm_15 fs_16 mt-4">
              Discover an expansive showcase of skills within our portfolio,
              featuring standout projects including captivating e-commerce
              platforms, dynamic social media applications, and an array of
              real-world projects that mirror the rich tapestry of modern web
              development.
            </div>
          </div>
          <div className="mt-5 pt-5">
            <div className=" d-flex align-items-center gap-5">
              <div>
                <img width="60px" src={briefcaseicon} />
              </div>
              <div className="subtitless open_sans">What about the Job?</div>
            </div>
            <div className="open_sans color_969696 fs_sm_15 fs_16 mt-4">
              From the third month of the course, your journey towards career
              readiness begins. You'll step into a structured interview process
              that hones your skills and prepares you for the professional
              world. Engage in mock interviews, refine your resume, and enhance
              your communication prowess. By the end of the course, you'll have
              gained the confidence to take on real job interviews with poise
              and competence. Our commitment to your success extends to
              assisting you in securing job opportunities, setting you on a path
              towards a fulfilling and thriving career.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMore;
