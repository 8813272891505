import React from 'react'
import happcrediticon from "../../assets/Happy-credit-logo.png";
import Indianexpressicon from "../../assets/Layer_x0020_1.png";
import depaulicon from "../../assets/DePaul-University-logo.png";
import ranchpalicon from "../../assets/Ranchpal-logo.png";
import homecheficon from "../../assets/Home-chef-logo.png";
import drivelahicon from "../../assets/logo-footer.png";
import InfosysLogo from "../../assets/Infosys-logo.png"
import WiproLogo from "../../assets/wipro-logo.png"
import TcsLogo from "../../assets/TCS-logo.png"
import HappyCreditLogo from "../../assets/Happy-credit-logo.png"
import "../style.css";
const Partners = () => {
    const ourAluminiImg = [
        {
            image: Indianexpressicon,
            imgName: "indian_express",
            width: "150",
            height: "100"
        },
        {
            image: InfosysLogo,
            imgName: "infosys",
            width: "80",
            height: "100"
        },
        {
            image: WiproLogo,
            imgName: "wipro",
            width: "80",
            height: "100"
        },
        {
            image: TcsLogo,
            imgName: "tcs",
            width: "100",
            height: "100"
        },
        {
            image: HappyCreditLogo,
            imgName: "happy_credit",
            width: "200",
            height: "125"
        },
        {
            image: depaulicon,
            imgName: "depaul_university",
            width: "150",
            height: "100"
        },
        {
            image: ranchpalicon,
            imgName: "ranchpal",
            width: "120",
            height: "100"
        },
        {
            image: homecheficon,
            imgName: "home_chef",
            width: "110",
            height: "100"
        },
        {
            image: drivelahicon,
            imgName: "drivelah",
            width: "80",
            height: "100"
        },
    ]
    return (
        <div className="mt-5 py-5 bgcolor mt-5 pt-5 partners_container">
            <div className=' d-flex justify-content-center'>
                <div className='hiringprtnrs titlepayment d-flex justify-content-center DM_Serif_Text'>
                    Hiring Partners
                </div>
            </div>
            <div className="backgroundcolor">
                {/* <div className="d-flex justify-content-center row g-4 gy-xl-2 gy-lg-5 justify-content-around justify-content-sm-center align-items-center col-12 col-xl-11 col-xxl-10 mx-auto">
                    <div className=" d-flex justify-content-center">
                        <div className="col-lg-12">
                            <div className="row justify-content-center g-0 gx-0 mt-4">
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-3 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div>
                                                <img src={happcrediticon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-5 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div className='pt-5 mt-lg-3 mt-sm-0'>
                                                <img width="170px" className='' src={Indianexpressicon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-3 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div>
                                                <img src={depaulicon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-3 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div>
                                                <img src={ranchpalicon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-3 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div>
                                                <img src={homecheficon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 col-md-4 col-sm-6 mt-3 ps-3">
                                    <div className=" d-flex justify-content-center">
                                        <div className=" d-flex gap-2 text-center justify-content-center">
                                            <div className='pt-4'>
                                                <img src={drivelahicon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='row g-0 justify-content-center'>
                    <div className='col-xxl-6 col-lg-8 col-md-10 col-12 px-3 px-md-0'>
                        <div className='row justify-content-center'>
                            {ourAluminiImg?.map((item, index)=>{
                                if(index < 4){
                                    return(
                                        <div className='col-md-3 col-6 d-flex justify-content-center align-items-center'>
                                          <img cl width={item?.width} height={item?.height} style={{objectFit:"contain"}} className={`image_design`} src={item?.image}/>
                                        </div>
                                    )
                                }
                            })}
                            
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                <div className='col-xxl-9 col-12'>
                <div className='row g-0 gap-md-4 justify-content-center'>
                {ourAluminiImg?.map((item, index) =>{
                    if(index > 3){
                        return(
                            <div className='col-md-2 col-sm-4 col-6 d-flex justify-content-center align-items-center'>
                                <img width={item?.width} height={item?.height} style={{objectFit:"contain"}} className='image_design' src={item?.image}/>
                            </div>
                        )
                    }
                    
                })}
                </div>
                </div>
                </div>
                
                
            </div>
        </div>

    )
}

export default Partners