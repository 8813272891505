import React, { useEffect, useState } from "react";
import "../style.css";
import "./curriculum.css";
import commenticon from "../../assets/comment-dots 1.png";
import headerimg from "../../assets/Group 40.png";
import arrow from "../../assets/Line 2.png";
import FormModal from "../formmodal/FormModal";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import "../Curriculum/curriculum.css";
import { useLocation } from "react-router";
import FullStackImg from "../../assets/full-stack-curricullum-img.png";
import FrontEndImg from "../../assets/Front-end-dev-img.png";
const FrontEntDevCurriculum = () => {
  const router = useLocation();
  const [formModalShow, setFormModalShow] = useState(false);
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  const coursesDetails = [
    {
      courseImg: FrontEndImg,
      courseLabel:
        "Node.js, React, and Beyond: Unlocking the Secrets of Front-End Mastery.",
      courseTitle: "Elevate Your Coding: A Journey through HTML, CSS, and React.",
      courseDescription:
        "Innovate with HTML, CSS, and React: Mysore's & Bangalore Unique Learning Realm for Unleashing Creativity.",
      courseType: "front_end",
    },
  ];
  return (
    <>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Curriculum"}
        description={"Unlock Your Potential: Download Now!"}
        selectedCourse="front_end"
      />
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Enroll now"}
        description={"Unlock Your Potential: Enroll Now!"}
        selectedCourse="front_end"
      />
      <div className="mt-5">
        <div className="col-md-11 pb-5 pb-sm-0">
          <div className="d-lg-flex justify-content-center align-items-center">
            {coursesDetails?.map((item) => {
              return (
                <>
                  <div className="col-lg-6 col-12 d-none d-lg-block">
                    <div className="d-flex justify-content-end ms-5 ms-xxl-0">
                      <img className="w-75 d-none d-xxl-block" src={item?.courseImg} />
                      <img className="w-100 d-xxl-none" style={{maxWidth:"90rem"}} src={item?.courseImg} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                    <div className="mt-5 px-lg-5 mx-5 ms-lg-5 me-lg-0">
                      <div className="d-flex text-align-center align-items-center gap-2 ms-lg-5 ps-lg-5 ">
                        <div>
                          <img src={commenticon} />
                        </div>
                        <div className="open_sans commenticon fs_15 fs_sm_12 ">
                          {item?.courseLabel}
                        </div>
                      </div>
                      <div
                        className="fs_lg_28 fs_36 letter_spacing fw_600 DM_Serif_Text mt-3 ms-lg-5 ps-lg-5 line_height_4rem"
                        style={{ maxWidth: "500px" }}
                      >
                        {item?.courseTitle}
                      </div>
                      <div className="embark open_sans mt-4 ms-lg-5 ps-lg-5">
                        {item?.courseDescription}
                      </div>
                      <div className="d-flex gap-5 mt-4 ms-lg-5 ps-lg-5">
                        <button
                          className="enrollblubtn px-4 py-2 "
                          onClick={() => handleEnrollModalShow()}
                        >
                          Enroll Now
                        </button>
                        <button
                          className="curriculumbtn"
                          onClick={() => handleFormModalShow()}
                        >
                          <span>Curriculum</span>
                          <span className="ps-3">
                            <img src={arrow} />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-block d-lg-none">
                    <div className="d-flex justify-content-center mx-5 mx-md-0">
                      <img className="w-100 mt-5" style={{maxWidth:"60rem"}} src={item?.courseImg} />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontEntDevCurriculum;
