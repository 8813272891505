import React, { useEffect, useState } from "react";
import ArrowUpIcon from "../../assets/Arrow-Up-icon.png";
const GoToTop = () => {
  const [isTopUpIconVisible, setIsTopUpIconVisible] = useState(false);
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const listenToScroll = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (windowScroll > 1000) {
      setIsTopUpIconVisible(true);
    } else {
      setIsTopUpIconVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return() => window.removeEventListener("scroll", listenToScroll);
  }, []);
  return (
    <div>
      {isTopUpIconVisible && (
        <div className="text-end" onClick={() => goToTop()}>
          <img
            className="arrowuo_icon"
            style={{ maxWidth: "25px", cursor: "pointer" }}
            src={ArrowUpIcon}
            alt="ArrowUpIcon"
          />
        </div>
      )}
    </div>
  );
};

export default GoToTop;
