import React, { useState } from "react";
import logo from "../../assets/footericon.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import phoneicon from "../../assets/phone.png";
import mailicon from "../../assets/mail.png";
import locationicon from "../../assets/location.png";
import axios from "axios";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import { useNavigate } from "react-router";
import "./footer.css";

const Footer = ({ scrollToSection }) => {
  const almndploicy = "https://s3.ap-south-1.amazonaws.com/zs2.0/ALMND.pdf";

  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = almndploicy;
    link.target = "_blank";
    link.download = "Syllabus.pdf";
    link.click();
  };

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const phoneNumber = "+91 6364840327";
  const handleApi = (values) => {
    axios
      .post("https://api.almnd.in/api/news-letter-create", {
        email_address: values?.email,
      })
      .then((result) => {
        console.log(result.data, "hgghxgfxgfx");
        SuccessToast(result.data.message);
        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };

  const [successMessageShow, setSuccessMessageShow] = useState(false);
  const handleSuccessMessageClose = () => {
    setSuccessMessageShow(false);
  };
  const handleSuccessMessageShow = () => setSuccessMessageShow(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("!Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      // saveData();
      // handleSuccessMessageShow();
      handleApi(values);
      resetForm();
      setError(null);
    },
  });
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="footer_background mt-5">
      <div className="col-lg-12 foot pt-3">
        <div className="row sections g-0 ps-sm-5 mt-5 pt-2">
          <div className="col-lg-4 col-md-6 col-sm-12 px-4">
            <div className="zameenbrand mt-5 cursor_pointer">
              <img
                src={logo}
                onClick={() => {
                  navigate("/");
                  goToTop();
                }}
              />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="subscribe DM_Serif_Text mt-5 pt-4">
                Newsletter
              </div>

              <div className="newsLetter-input-field d-flex align-items-center justify-content-between">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="footerbox open_sans fs_14 fs_sm_12 p-1 ms-3 newsLetter_ph"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <button
                  type="submit"
                  className="subscribebtn text-center p-1 px-3 fs_14 fs_sm_12 text-nowrap"
                >
                  Get Notifications
                </button>
              </div>

              {formik.touched.email && formik.errors.email ? (
                <div className="errorcolor open_sans ps-2">
                  {formik.errors.email}
                </div>
              ) : null}
            </form>
            <div>{error}</div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 ps-4">
            <h5 className="quicklink DM_Serif_Text mt-5">Policies</h5>
            <div className="footerblogs open_sans">
              {/* <div className="footerblogs mt-4">Amnd Policy</div> */}
              <div
                className="footerblogs mt-3 cursor_pointer"
                onClick={() => downloadPDF()}
              >
                Almnd Policy
              </div>
              <div className="footerblogs mt-3 cursor_pointer ">
                <a
                  className="text-decoration-none text-white"
                  target="_blank"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>
              </div>
              {/* <div className="footerblogs mt-3">Terms & Conditions</div> */}
              <div
                onClick={() => scrollToSection("ContactUs")}
                className="footerblogs mt-3 cursor_pointer"
              >
                Contact Us
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 ps-4">
            <h5 className="quicklink DM_Serif_Text mt-5">Socials</h5>
            <div className="footerblogs open_sans">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61550342099975"
              >
                <div className="footerblogs mt-4">Facebook</div>
              </a>
              <a
                className="text-decoration-none text-white"
                href="https://www.linkedin.com/in/join-almnd-1066a3288/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="footerblogs mt-3"> Linkedin</div>
              </a>
              <a href="https://www.instagram.com/joinalmnd/" target="_blank">
                <div className="footerblogs mt-3">Instagram</div>
              </a>
              <a href="https://twitter.com/JoinAlmnd" target="_blank">
                <div className="footerblogs mt-3">Twitter</div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCrXCDtSDzkC8tRVMgCBTHKw"
                target="_blank"
              >
                <div className="footerblogs mt-3">Youtube</div>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 ps-4">
            <h5 className="quicklink DM_Serif_Text mt-5">Contact Us</h5>
            <div className="footerblogs open_sans mt-5 d-flex align-content-center">
              <div>
                <img src={phoneicon} />
              </div>
              <div className="ps-3">
                <a
                  href={`tel:${phoneNumber}`}
                  className="text-white fs_xs_14 fs_16 ms-2 mt-1 text-decoration-none"
                >
                  {phoneNumber}
                </a>
              </div>
            </div>
            <div className="footerblogs open_sans mt-4 d-flex align-content-center">
              <div>
                <img src={mailicon} />
              </div>
              <div className="ps-3">
                <a
                  className="text-white fs_xs_14 fs_16 ms-2 text-decoration-none"
                  target="_blank"
                  href="mailto:join@almnd.in"
                >
                  join@almnd.in
                </a>
              </div>
            </div>
            <div className="footerblogs open_sans mt-4 d-flex align-content-center">
              <div>
                <img src={locationicon} />
              </div>
              <div className="ps-3 col-md-8 me-4 memd-0">
                <a
                  className="text-decoration-none text-white"
                  href="https://maps.app.goo.gl/pxXMNfaGAAmPakVY8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2nd Floor, Emirates Arcade, 80 Feet Rd, behind Water Tank,
                  Manchegowdana koppalu, II Stage, Vijayanagar, Mysuru,
                  Karnataka 570017
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-md-5 pt-5">
        <hr className="footerwidthline " />
      </div>
      <div className=" d-flex justify-content-center px-5 py-4">
        <div className="copyfont open_sans pb-4">
          © 2023 First Floor Infotech Private Limited All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
