import React, { useEffect, useRef, useState } from "react";
import Faq from "react-faq-component";
import PlusIcon from "../../assets/plus-circle.png";
import MinusIcon from "../../assets/minus-icon.png";
import DotIcon from "../../assets/circle-line.png";

const MobileDevFaq = ({ scrollToSection }) => {
  const [dropDown, setDropDown] = useState(-1);
  const accordionRefs = useRef([]);
  const faqData = [
    {
        title:
          "What does Mobile Development encompass?",
        content:
          "Mobile Development focuses on creating applications for mobile devices, including smartphones and tablets, for both Android and iOS platforms.",
      },
      {
        title:
          "Is this course beginner-friendly for individuals with no programming background?",
        content: "Yes, this course is designed to accommodate beginners with no prior programming experience.",
      },
      {
        title:
          "Are there specific prerequisites for enrolling in the Mobile Development course?",
        content: "There are no specific prerequisites. This course is open to anyone interested in learning mobile app development.",
      },
      {
        title: "What is the duration of the Mobile Development course, and can I choose between online and offline modes of learning?",
        content: "The course spans 16 weeks and offers both online and offline learning modes, allowing you to select the one that suits your preferences.",
      },
      {
        title:
          "What key skills and technologies will I acquire during this course?",
        content: "Throughout the course, you will gain expertise in cross-platform mobile app development with Flutter, native Android development, iOS app development, database integration, and more.",
      },
      {
        title:
          "Will I receive a certification upon successful completion of the Mobile Development course?",
        content: "Yes, upon successfully completing the course, you will be awarded a certificate that can enhance your career opportunities.",
      },
      {
        title:
          "Can I access course materials and resources after completing the course?",
        content: "Yes, you will have continued access to course materials, which can serve as valuable references for future mobile app projects.",
      },
      {
        title:
          "Does the course provide job placement assistance for Mobile Development graduates?",
        content: "Absolutely, we offer job placement support and guidance to help you kickstart your career in Mobile Development.",
      },
      {
        title:
          "Are there specific hardware or software prerequisites for this course?",
        content: "You will need access to a computer and an internet connection. Specific software tools required for the course will be provided or recommended.",
      },
      {
        title:
          "How can I enrol in the Mobile Development course, and what is the fee structure?",
        content: "To enrol, contact our admissions team for comprehensive details regarding fees and the enrollment process.",
      },
];
  
  const toggelFn = (index) => {
    if (dropDown === index) {
      setDropDown(-1);
    } else {
      setDropDown(index);
    }
  };
  useEffect(() => {
    console.log(accordionRefs, "accordionRefs");
    if (dropDown !== -1) {
      accordionRefs.current.forEach((ref, index) => {
        if (ref) {
          if (index === dropDown) {
            ref.style.maxHeight = `${ref.scrollHeight}px`;
          } else {
            ref.style.maxHeight = "0";
          }
        }
      });
    } else {
      accordionRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.maxHeight = "0";
        }
      });
    }
  }, [dropDown]); 
  return (
    <div className=" background py-5 px-5 px-sm-0 mt-sm-5"> 
      <div className="text-white fs_sm_22 fs_40 fs_35 d-flex justify-content-center DM_Serif_Text text-center px-1 px-sm-0">
        Frequently Asked Questions
      </div>
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div
          className="subtitle w-100 mt-3 px-5 px-sm-0 open_sans"
          style={{ maxWidth: "650px" }}
        >
          If you have any questions or need additional information about us, please don't hesitate to reach out. We're here to provide you with the answers and support you need to make the most of this opportunity.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-5">
        <div className="col-lg-5 w-100 fs_14" style={{ maxWidth: "550px" }}>
          {/* <Faq data={data} styles={styles} config={config} /> */}
          {faqData.map((item, index, array) => {
            return (
              <>
                <div className="d-flex justify-content-between w-100 mt-4 mb-3 py-sm-1">
                  <span className="me-3">
                    <div className="fs_xs_16 fs_18 secondary_color fw_400 text-white d-flex">
                      <span>
                        <img width={5} height={5} src={DotIcon} />
                      </span>
                      <span className="ms-3 fs_18 fs_md_16">{item?.title}</span>
                    </div>
                    <div
                      ref={(el) => (accordionRefs.current[index] = el)}
                      className={`mt-2 ${
                        dropDown === index
                          ? `accordion_content open`
                          : `accordion_content`
                      }`}
                    >
                      <div className="color_E3E3E3 fs_16 fs_md_14 ms-4">
                        {item?.content}
                      </div>
                    </div>
                  </span>
                  <span className="faq_cursor" onClick={() => toggelFn(index)}>
                    <img
                      src={dropDown === index ? MinusIcon : PlusIcon}
                      height={20}
                    />
                  </span>
                </div>
                <hr className="footerwidthline"/>
              </>
            );
          })}
        </div>
      </div>
      <div className="titles d-flex justify-content-center mt-5 DM_Serif_Text pt-sm-5">
        Still have questions?
      </div>
      {/* <div className=" d-flex justify-content-center align-content-center text-center">
        <div className="subtitles mt-2 open_sans">
          Can't find the answer you're looking for? Please connect with our
          friendly team.
        </div>
      </div> */}
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div className=" col-xl-3 col-md-5  col-12 subtitles mt-2 open_sans">
          Can't find the answer you're looking for? Please connect with our
          friendly team.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-4 mb-5">
        <button
          className="connectbtn py-2 px-5 open_sans"
          onClick={() => scrollToSection("ContactUs")}
        >
          Connect
        </button>
      </div>
    </div>
  );
};

export default MobileDevFaq;
