import React, { useEffect, useState } from "react";
import point from "../../assets/Vector (1).png";
import points from "../../assets/Vector (2).png";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import PostpaidModal from "../formmodal/PostpaidModal";
import PrepaidModal from "../formmodal/PrepaidModal";
import { useLocation } from "react-router";
const PaymentCards = () => {
  const router = useLocation();
  const [activetab, setActivetab] = useState("");
  const [PostpaidModalShow, setPostpaidModalShow] = useState(false);
  const [mode, setMode] = useState("");
  const handlePostpaidModalClose = () => {
    setPostpaidModalShow(false);
  };
  const handlePostpaidModalShow = () => setPostpaidModalShow(true);

  const [PrepaidModalShow, setPrepaidModalShow] = useState(false);
  const handlePrepaidModalClose = () => {
    setPrepaidModalShow(false);
  };
  const handlePrepaidModalShow = () => setPrepaidModalShow(true);
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else if (router.pathname.startsWith("/front-end-development-course")) {
      return "front_end";
    } else if (router.pathname.startsWith("/back-end-development-course")) {
      return "back_end";
    } else if (router.pathname.startsWith("/mobile-development-course")) {
      return "mobile";
    }
  };

  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActivetab(newActiveTab);
  }, [router.pathname]);
  const coursePlan = [
    {
      courseType: "Full-Stack Development",
      courseAmount: "35,000",
      assistance: "10,000",
      course: "full_stack",
    },
    {
      courseType: "Front-End Development",
      courseAmount: "20,000",
      assistance: "10,000",
      course: "front_end",
    },
    {
      courseType: "Back-End Development",
      courseAmount: "20,000",
      assistance: "10,000",
      course: "back_end",
    },
    {
      courseType: "Mobile Development",
      courseAmount: "20,000",
      assistance: "10,000",
      course: "mobile",
    },
  ];
  return (
    <>
      <PostpaidModal
        PostpaidModalShow={PostpaidModalShow}
        handlePostpaidModalClose={handlePostpaidModalClose}
        title={"Postpaid"}
        description={"Unlock Your Potential: Join Now!"}
        mode={mode}
        selectedCourse={activetab}
      />
      <PrepaidModal
        PrepaidModalShow={PrepaidModalShow}
        handlePrepaidModalClose={handlePrepaidModalClose}
        title={"Prepaid"}
        description={"Unlock Your Potential: Join Now!"}
        mode={mode}
        selectedCourse={activetab}
      />
      <div className="py-5 mt-5">
        <div className="titlepayment d-flex justify-content-center  DM_Serif_Text">
          Fees and Plan
        </div>

        <div className=" d-flex  justify-content-center align-content-center text-center">
          <div className=" col-xl-5 col-md-8 col-12 color_969696 fs_sm_15 fs_16 mt-3 open_sans px-5 px-sm-0 mx-5 mx-sm-0">
            Transparent Fee Structure Experience full transparency with our
            straightforward fee structure, ensuring you invest in your tech
            journey with complete clarity.
          </div>
        </div>
        <div className=" d-flex justify-content-center mt-1">
          <div className="col-md-10 col-11">
            <div className=" d-flex justify-content-center">
              <div className=" d-md-flex justify-content-center gap-5">
                <div className="col-md-6 col-12">
                  {coursePlan
                    ?.filter((item) => activetab === item?.course)
                    ?.map((item) => {
                      return (
                        <div className="mt-5 cardpayment">
                          <div className="topbg px-5 py-5">
                            <div className="text-white open_sans fs_14">
                              {item?.courseType}
                            </div>
                            <div className="DM_Serif_Text studynow">
                              Online Mode
                            </div>
                          </div>
                          <div className="px-5 py-3">
                            <div className=" d-flex align-items-center mt-3">
                              <div>
                                <img width="15px" src={point} />
                              </div>
                              <div className="ps-3 open_sans feedetails">
                                Course Fees : ₹ {item?.courseAmount}/- +GST
                              </div>
                            </div>
                            <div className=" d-flex mt-4">
                              <div>
                                <img width="15px" src={point} />
                              </div>
                              <div className="open_sans feedetails ps-3">
                                Placement Assistance: ₹ {item?.assistance}
                              </div>
                            </div>
                            <div className=" d-flex mt-4">
                              <div>
                                <img width="15px" src={point} />
                              </div>
                              <div className="open_sans feedetails ps-3 fw_600">
                                Next Batch Starts On <br/> OCTOBER 1st, 2023
                              </div>
                            </div>
                            <div className=" d-flex  justify-content-center mt-4 mb-3">
                              <button
                                className="joinnowbtns px-5 p-3 col-12"
                                onClick={() => {handlePostpaidModalShow(); setMode("online_mode")}}
                              >
                                Join Now
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-md-6 col-12">
                  {coursePlan?.filter((item) => activetab === item?.course)?.map((item) =>{
                    return(
                      <div className="mt-5 cardpayment">
                    <div className="topbg px-5 py-5">
                      <div className="text-white open_sans fs_14">
                        {item?.courseType}
                      </div>
                      <div className="DM_Serif_Text studynow">
                        Offline Mode
                      </div>
                    </div>
                    <div className="px-5 py-3">
                      <div className=" d-flex align-items-center mt-3">
                        <div>
                          <img width="15px" src={points} />
                        </div>
                        <div className="ps-3 open_sans feedetails">
                          Course Fee : ₹ {item?.courseAmount}/- +GST
                        </div>
                      </div>
                      <div className=" d-flex align-items-center mt-4">
                        <div>
                          <img width="15px" src={points} />
                        </div>
                        <div className="open_sans feedetails ps-3">
                          Placement Assistance: ₹ {item?.assistance}
                        </div>
                      </div>
                      <div className=" d-flex mt-4">
                        <div>
                          <img width="15px" src={points} />
                        </div>
                        <div className="open_sans feedetails ps-3 fw_600">
                          Next Batch Starts On <br/> OCTOBER 1st, 2023
                        </div>
                      </div>
                      <div className=" d-flex justify-content-center mt-4 mb-3">
                        <button
                          className="joinnowbtn px-5 p-3 col-12"
                          onClick={() => {handlePrepaidModalShow(); setMode("offline_mode")}}
                        >
                          Join Now
                        </button>
                      </div>
                    </div>
                  </div>
                    )
                  })}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCards;
