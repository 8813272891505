import React, { useEffect, useRef, useState } from "react";
import Faq from "react-faq-component";
import PlusIcon from "../../assets/plus-circle.png";
import MinusIcon from "../../assets/minus-icon.png";
import DotIcon from "../../assets/circle-line.png";



const BackEndFaq = ({ scrollToSection }) => {
  const [dropDown, setDropDown] = useState(-1);
  const accordionRefs = useRef([]);
  const faqData = [
    {
        title:
          "What is the primary focus of Back-End Development?",
        content:
          "Back-End Development primarily deals with server-side programming and database management to ensure the functionality and data management of websites and web applications.",
      },
      {
        title:
          "Is this course beginner-friendly for non-technical backgrounds?",
        content: "Absolutely, this course is beginner-friendly and open to individuals from various educational backgrounds, even those without a technical or computer science foundation.",
      },
      {
        title:
          "Are there specific prerequisites for enrolling in the Back-End Development course?",
        content: "No, there are no specific prerequisites. This course is designed to accommodate learners with diverse backgrounds and experiences.",
      },
      {
        title: "How long does the course last, and what are the available modes of learning?",
        content: "The course spans 16 weeks and offers both offline and online modes of learning, providing flexibility to suit students' preferences.",
      },
      {
        title:
          "What key skills and concepts will I acquire during this course?",
        content: "Throughout the course, you will gain proficiency in database management, server-side scripting, API development, and ensuring the functionality of web applications.",
      },
      {
        title:
          "Will I receive a certification upon successfully completing the Back-End Development course?",
        content: "Yes, upon successfully completing the course, you will be awarded a certification that can enhance your career prospects.",
      },
      {
        title:
          "Can I access course materials and resources post-completion for reference purposes?",
        content: "Yes, you will have continued access to course materials, allowing you to revisit and use them as valuable references for future projects.",
      },
      {
        title:
          "Does the course provide job placement assistance for Back-End Development graduates?",
        content: "Certainly, we offer job placement support and guidance to help you launch your career in the Back-End Development field.",
      },
      {
        title:
          "Are there specific hardware or software prerequisites for this course?",
        content: "You will need access to a computer and an internet connection. Specific software tools required for the course will be either provided or recommended.",
      },
      {
        title:
          "How can I enrol in the Back-End Development course, and what is the fee structure?",
        content: "To enrol, get in touch with our admissions team for comprehensive details regarding fees and the enrollment process.",
      },
];
  const toggelFn = (index) => {
    if (dropDown === index) {
      setDropDown(-1);
    } else {
      setDropDown(index);
    }
  };
  useEffect(() => {
    console.log(accordionRefs, "accordionRefs");
    if (dropDown !== -1) {
      accordionRefs.current.forEach((ref, index) => {
        if (ref) {
          if (index === dropDown) {
            ref.style.maxHeight = `${ref.scrollHeight}px`;
          } else {
            ref.style.maxHeight = "0";
          }
        }
      });
    } else {
      accordionRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.maxHeight = "0";
        }
      });
    }
  }, [dropDown]); 
  return (
    <div className=" background py-5 px-5 px-sm-0 mt-sm-5"> 
      <div className="text-white fs_sm_22 fs_40 fs_35 d-flex justify-content-center DM_Serif_Text text-center px-1 px-sm-0">
        Frequently Asked Questions
      </div>
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div
          className="subtitle w-100 mt-3 px-5 px-sm-0 open_sans"
          style={{ maxWidth: "650px" }}
        >
         If you have any questions or need additional information about us, please don't hesitate to reach out. We're here to provide you with the answers and support you need to make the most of this opportunity.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-5">
        <div className="col-lg-5 w-100 fs_14" style={{ maxWidth: "550px" }}>
          {/* <Faq data={data} styles={styles} config={config} /> */}
          {faqData.map((item, index, array) => {
            return (
              <>
                <div className="d-flex justify-content-between w-100 mt-4 mb-3 py-sm-1">
                  <span className="me-3">
                    <div className="fs_xs_16 fs_18 secondary_color fw_400 text-white d-flex">
                      <span>
                        <img width={5} height={5} src={DotIcon} />
                      </span>
                      <span className="ms-3 fs_18 fs_md_16">{item?.title}</span>
                    </div>
                    <div
                      ref={(el) => (accordionRefs.current[index] = el)}
                      className={`mt-2 ${
                        dropDown === index
                          ? `accordion_content open`
                          : `accordion_content`
                      }`}
                    >
                      <div className="color_E3E3E3 fs_16 fs_md_14 ms-4">
                        {item?.content}
                      </div>
                    </div>
                  </span>
                  <span className="faq_cursor" onClick={() => toggelFn(index)}>
                    <img
                      src={dropDown === index ? MinusIcon : PlusIcon}
                      height={20}
                    />
                  </span>
                </div>
                <hr className="footerwidthline"/>
              </>
            );
          })}
        </div>
      </div>
      <div className="titles d-flex justify-content-center mt-5 DM_Serif_Text pt-sm-5">
        Still have questions?
      </div>
      {/* <div className=" d-flex justify-content-center align-content-center text-center">
        <div className="subtitles mt-2 open_sans">
          Can't find the answer you're looking for? Please connect with our
          friendly team.
        </div>
      </div> */}
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div className=" col-xl-3 col-md-5  col-12 subtitles mt-2 open_sans">
          Can't find the answer you're looking for? Please connect with our
          friendly team.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-4 mb-5">
        <button
          className="connectbtn py-2 px-5 open_sans"
          onClick={() => scrollToSection("ContactUs")}
        >
          Connect
        </button>
      </div>
    </div>
  );
};

export default BackEndFaq;
