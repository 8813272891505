import React, { useState } from "react";
import ArrowIcon from "../../assets/Group.png";
import weekimg from "../../assets/week list.png";
import leftarrow from "../../assets/Group 23.png";
import rightarrow from "../../assets/Group 26.png";
import { array } from "yup";
import "./weekTimeline.css";
import useScreenSize from "../helper/UserScreenSize";

const MobileDevWeekTimeLine = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const width = useScreenSize();
  const weekList = [
    {
      value: 1,
      title: "Week 1",
      session1: "Introduction to Mobile Development with Flutter",
      session2: "Overview of Mobile App Development",
      session3: "Setting Up Android Studio and Flutter Environment",
      session4: "Creating Your First Flutter App",

    },
    {
      value: 2,
      title: "Week 2",
      session1: "Understanding Widgets in Flutter",
      session2: "Introduction to Widgets",
      session3: "Stateless and Stateful Widgets",
      session4: "Widget Composition"
    },
    {
      value: 3,
      title: "Week 3",
      session1: "Layouts and Containers in Flutte",
      session2: "Container Widgets (Container, Row, Column)",
      session3: "Flutter Layouts (SingleChildScrollView, ListView, GridView)",
      session4: "Handling User Input in Widgets"
    },

    {
      value: 4,
      title: "Week 4",
      session1: "Flutter UI and Styling",
      session2: "Styling Widgets with Themes",
      session3: "Text and Typography",
      session4: "Customizing Widgets",
    },
    {
      value: 5,
      title: "Week 5",
      session1: "Navigation and Routing in Flutter",
      session2: "Routing in Flutter",
      session3: "Navigation Drawer and Tabs",
      session4: "Passing Data Between Screens"
    },
    {
      value: 6,
      title: "Week 6",
      session1: "State Management with BLoC in Flutter (Part 1)",
      session2: "Introduction to BLoC Pattern",
      session3: "Setting up BLoC with RxDart",
      session4: "Managing Application State"
    },
    {
      value: 7,
      title: "Week 7",
      session1: "State Management with BLoC in Flutter (Part 2)",
      session2: "Implementing Business Logic with BLoC",
      session3: "Reactive Programming with Streams",
      session4: "Advanced BLoC Patterns"
    },
    {
      value: 8,
      title: "Week 8",
      session1: "Working with APIs in Flutter",
      session2: "Fetching Data from REST APIs",
      session3: "Parsing JSON Data",
      session4: "Error Handling and Data Caching"
    },
    {
      value: 9,
      title: "Week 9",
      session1: "Flutter Package Management (pub.dev)",
      session2: "Exploring Pub.dev",
      session3: "Installing and Using Packages",
      session4: "Dependency Management"
    },
    {
      value: 10,
      title: "Week 10",
      session1: "Authentication and User Management",
      session2: "User Authentication with Firebase",
      session3: "Firebase Authentication Services",
      session4: "Securing User Data"
    },
    {
      value: 11,
      title: "Week 11",
      session1: "Flutter App Testing",
      session2: "Writing Unit Tests for Widgets and BLoC",
      session3: "Integration Testing",
      session4: "Debugging and Profiling"
    },
    {
      value: 12,
      title: "Week 12",
      session1: "Deploying Flutter Apps",
      session2: "Preparing Apps for Deployment",
      session3: "Google Play Store and Apple App Store Guidelines",
      session4: "Building Release Builds"
    },
    {
      value: 13,
      title: "Week 13",
      session1: "Advanced Topics in Flutter",
      session2: "Flutter Animations",
      session3: "Flutter for Web and Desktop",
      session4: "Flutter for IoT and Embedded Systems",
    },
    {
      value: 14,
      title: "Week 14",
      session1: "Working on Real-World Projects (Part 1)",
      session2: "Individual or Group Flutter Projects",
      session3: "Implementing Advanced Features",
      session4: "Project Planning and Design",

    },
    {
      value: 15,
      title: "Week 15",
      session1: "Working on Real-World Projects (Part 2)",
      session2: "Project Development Continues",
      session3: "Testing and Debugging of Projects",
      session4: "Documentation and User Testing",

    },
    {
      value: 16,
      title: "Week 16",
      session1: "Project Completion and Presentation",
      session2: "Finalise and present Flutter Projects",
    },
  ];
  return (
    <>
      <div className=" d-flex justify-content-center mt-5 mx-auto">
        <div className="col-lg-10 col-12 mt-5" style={{overflowX: width < 768 && "scroll"}}>
          <div className="row g-0" style={{width: width < 768 && "700px", overflowX: width < 768 && "scroll"}}>
            <div className="col-lg-3 col-12 px-4 time_line border_radus_7px">
              <div className="p-2 fs_30 DM_Serif_Text black_color pb-4 text-nowrap">Time Line</div>
              <div className="custom-container d-lg-block d-none">
                {weekList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex open_sans fs_18 fw-bold mt-5 cursor_pointer text-nowrap"
                        onClick={() => setCurrentWeek(item?.value)}
                      >
                        {item?.value === currentWeek ? (
                          <>
                            <img className="" src={ArrowIcon} height={"20px"} width={"20px"} />
                          </>
                        ) : null}
                        <span className="ps-3">{item?.title}</span>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="horizontal-container d-lg-none d-block">
                <div className="d-flex gap-5">
                  {weekList?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="open_sans fs_15 mt-5 cursor_pointer text-nowrap"
                          onClick={() => setCurrentWeek(item?.value)}
                        >
                          <button className={`${item?.value === currentWeek ? "redbtn px-3 p-2" : "nobtn px-3 p-2"}`}>
                            <span>{item?.title}</span>
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 px-3 mt-5 mt-lg-0">
              <div className=" d-flex justify-content-between ms-lg-5">
                <div className="p-2 fs_xs_24 fs_32 DM DM_Serif_Text black_color">Week {currentWeek}</div>
                <div className="d-lg-none d-lg-block">
                  <div className="d-flex justify-content-end align-items-center pe-2 mt-4">
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre - 1)}
                      onClick={() => {
                        if (currentWeek > 1) {
                          setCurrentWeek((pre) => pre - 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek < 16) {
                            horizontalContainer.scrollLeft -= 100;
                          }
                        }
                      }}

                      className="scrollbtns" disabled={currentWeek === 1}>
                      <img className=" w-50" src={leftarrow} />
                    </button>
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre + 1)} 
                      onClick={() => {
                        if (currentWeek < 16) {
                          setCurrentWeek((pre) => pre + 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek >= 1) {
                            horizontalContainer.scrollLeft += 100;
                          }
                        }
                      }}
                      className="scrollbtns" disabled={currentWeek === 16}>
                      <img className=" w-50" src={rightarrow} />
                    </button>
                  </div>
                </div>
              </div>
              {weekList
                ?.filter((item) => item?.value === currentWeek)
                .map((data) => {
                  return (
                      <div className=" position-relative ms-lg-5">
                        <div className="mt-3">
                          <img className="d-none d-md-block w-100 weekimg" height="500px" src={weekimg} />
                          <img className="d-block d-sm-none w-100 weekimg" height="350px" src={weekimg} />
                          <img className="d-none d-sm-block d-md-none w-100 weekimg" height="400px" src={weekimg} />
                        </div>

                        {
                              <div className=" text-center">
                                {data.session1 && <div className="session_11 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session1}
                                </div>}
                                {data?.session2 && <div className="session_22 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session2}
                                </div>}
                                {data?.session3 && <div className="session_33 fs_sm_10 fs_14 open_sans px-3 fw_600 me-3 me-sm-0">
                                  {data.session3}
                                </div>}
                                {data.session4 && <div className="session_44 fs_sm_10 fs_14 open_sans px-3 fw_600 me-1 me-sm-0">
                                  {data.session4}
                                </div>}
                              </div>
                        }
                      </div>
                  );
                })}
              <div className="d-none d-lg-block">
                <div className="d-flex justify-content-end pe-2 mt-5 gap-2">
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre - 1)}
                    onClick={() => {
                      if (currentWeek > 1) {
                        setCurrentWeek((pre) => pre - 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek <= 10) {
                          customContainer.scrollTop -= 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 1}>
                    <img className=" w-75" src={leftarrow} />
                  </button>
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre + 1)}
                    onClick={() => {
                      if (currentWeek < 16) {
                        setCurrentWeek((pre) => pre + 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek >= 7) {
                          customContainer.scrollTop += 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 16}>
                    <img className=" w-75" src={rightarrow} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDevWeekTimeLine;
