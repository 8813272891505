import React, { useEffect, useRef, useState } from "react";
import Faq from "react-faq-component";
import PlusIcon from "../../assets/plus-circle.png";
import MinusIcon from "../../assets/minus-icon.png";
import DotIcon from "../../assets/circle-line.png";
const data = {
  rows: [
    {
      title:
        "What is the eligibility criteria for enrolling in the Almnd Full Stack Development Course?,",
      content:
        "Any candidate studying or Completed UG/PG is a part of the program. ",
    },
    {
      title:
        "Is the course open to students with a non-Computer Science background?",
      content: "Yes",
    },
    {
      title:
        "Do I need to have prior programming experience to join the Full Stack Development Course?",
      content: "No",
    },
    {
      title: "Is the course suitable for beginners, or is it more advanced?",
      content: "It's for Beginners and who need to upgrade their career ",
    },
    {
      title:
        "Will students be provided with any learning materials or resources for self-study?",
      content: "Resources will be provided.",
    },
    {
      title:
        "What is the duration of the Full Stack Development Course at Almnd?",
      content: "3 to 4 Months",
    },
    {
      title:
        "Will the course provide hands-on projects to practice the concepts learned?",
      content: "Yes. No. of Projects - 6 Minimum",
    },
    {
      title:
        "How will the course be delivered - in-person, online, or a combination of both?",
      content: "In-Person",
    },
    {
      title:
        "What is the class size or student-to-instructor ratio for the course?",
      content: "30 : 1",
    },
    {
      title:
        "Will students receive any certifications upon successful completion of the course?",
      content: "Yes",
    },
    {
      title:
        "Are there any events related to the course that students can participate in?",
      content: "Yes",
    },
    {
      title:
        "Are there any additional costs beyond the course fees, such as textbooks or software licenses?",
      content: "No",
    },
  ],
};
const styles = {
  // bgColor: 'white',
  titleTextColor: "blue",
  rowTitleColor: "blue",
  rowContentColor: "white",
  arrowColor: "red",
  // titleTextColor: "black",
  rowTitleColor: "white",
  bgColor: "black",
  expandIconColor: `white`,
  collapseIcon: `white`,
  titleTextSize: "20px", // Adjust the font size for the title
  rowTitleTextSize: "16px", // Adjust the font size for the row titles
  rowContentTextSize: "14px",
};

const config = {
  expandIcon: "▼", // Replace with your desired icon for expansion
  collapseIcon: "▲",
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
  arrowIcon: "+",
  expandIcon: "+",
  collapseIcon: "-",
  // bgColor: 'black',
  // titleTextColor: 'DM_Serif_Text',
  titleTextSize: "10px",
  // rowTitleColor: 'blue',
  rowTitleTextSize: "10px",
  // rowContentColor: 'grey',
  rowContentTextSize: "10px",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "10px",
  rowContentPaddingRight: "150px",
  // arrowColor: "red",
  transitionDuration: "1s",
  timingFunc: "ease",
  animate: true,
  // arrowIcon: "V",
  openOnload: 0,
  // expandIcon: "+",
  // collapseIcon: "-",
};

const faqData = [
  {
    title:
      "What is the eligibility criteria for enrolling in the Almnd Full Stack Development Course?",
    content:
      "Any candidate studying or Completed UG/PG is a part of the program. ",
  },
  {
    title:
      "Is the course open to students with a non-Computer Science background?",
    content: "Yes",
  },
  {
    title:
      "Do I need to have prior programming experience to join the Full Stack Development Course?",
    content: "No",
  },
  {
    title: "Is the course suitable for beginners, or is it more advanced?",
    content: "It's for Beginners and who need to upgrade their career ",
  },
  {
    title:
      "Will students be provided with any learning materials or resources for self-study?",
    content: "Resources will be provided.",
  },
  {
    title:
      "What is the duration of the Full Stack Development Course at Almnd?",
    content: "3 to 4 Months",
  },
  {
    title:
      "Will the course provide hands-on projects to practice the concepts learned?",
    content: "Yes. No. of Projects - 6 Minimum",
  },
  {
    title:
      "How will the course be delivered - in-person, online, or a combination of both?",
    content: "In-Person",
  },
  {
    title:
      "What is the class size or student-to-instructor ratio for the course?",
    content: "30 : 1",
  },
  {
    title:
      "Will students receive any certifications upon successful completion of the course?",
    content: "Yes",
  },
  {
    title:
      "Are there any events related to the course that students can participate in?",
    content: "Yes",
  },
  {
    title:
      "Are there any additional costs beyond the course fees, such as textbooks or software licenses?",
    content: "No",
  },
];
const Faqs = ({ scrollToSection }) => {
  const [dropDown, setDropDown] = useState(-1);
  const accordionRefs = useRef([]);
  const toggelFn = (index) => {
    if (dropDown === index) {
      setDropDown(-1);
    } else {
      setDropDown(index);
    }
  };
  useEffect(() => {
    console.log(accordionRefs, "accordionRefs");
    if (dropDown !== -1) {
      accordionRefs.current.forEach((ref, index) => {
        if (ref) {
          if (index === dropDown) {
            ref.style.maxHeight = `${ref.scrollHeight}px`;
          } else {
            ref.style.maxHeight = "0";
          }
        }
      });
    } else {
      accordionRefs.current.forEach((ref) => {
        if (ref) {
          ref.style.maxHeight = "0";
        }
      });
    }
  }, [dropDown]); 
  return (
    <div className=" background py-5 px-5 px-sm-0 mt-sm-5"> 
      <div className="text-white fs_sm_22 fs_40 fs_35 d-flex justify-content-center DM_Serif_Text text-center px-1 px-sm-0">
        Frequently Asked Questions
      </div>
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div
          className="color_969696 fs_sm_15 fs_16 w-100 mt-3 px-5 px-sm-0 open_sans"
          style={{ maxWidth: "650px" }}
        >
         If you have any questions or need additional information about us, please don't hesitate to reach out. We're here to provide you with the answers and support you need to make the most of this opportunity.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-5">
        <div className="col-lg-5 w-100 fs_14" style={{ maxWidth: "550px" }}>
          {/* <Faq data={data} styles={styles} config={config} /> */}
          {faqData.map((item, index, array) => {
            return (
              <>
                <div className="d-flex justify-content-between w-100 mt-4 mb-3 py-sm-1">
                  <span className="me-3">
                    <div className="fs_xs_16 fs_18 secondary_color fw_400 text-white d-flex">
                      <span>
                        <img width={5} height={5} src={DotIcon} />
                      </span>
                      <span className="ms-3 fs_18 fs_md_15">{item?.title}</span>
                    </div>
                    <div
                      ref={(el) => (accordionRefs.current[index] = el)}
                      className={`mt-2 ${
                        dropDown === index
                          ? `accordion_content open`
                          : `accordion_content`
                      }`}
                    >
                      <div className="color_E3E3E3 fs_16 fs_sm_13 fs_md_14 ms-4">
                        {item?.content}
                      </div>
                    </div>
                  </span>
                  <span className="faq_cursor" onClick={() => toggelFn(index)}>
                    <img
                      src={dropDown === index ? MinusIcon : PlusIcon}
                      height={20}
                    />
                  </span>
                </div>
                <hr className="footerwidthline"/>
              </>
            );
          })}
        </div>
      </div>
      <div className="titles d-flex justify-content-center mt-5 DM_Serif_Text pt-sm-5">
        Still have questions?
      </div>
      <div className=" d-flex justify-content-center align-content-center text-center">
        <div className=" col-xl-3 col-md-5  col-12 subtitles mt-2 open_sans">
          Can't find the answer you're looking for? Please connect with our
          friendly team.
        </div>
      </div>
      <div className=" d-flex justify-content-center mt-4 mb-5">
        <button
          className="connectbtn py-2 px-5 open_sans"
          onClick={() => scrollToSection("ContactUs")}
        >
          Connect
        </button>
      </div>
    </div>
  );
};

export default Faqs;
