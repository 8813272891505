import React, { useState } from "react";
import workshopImg from "../../assets/medium-shot-people-learning 1.png";
import FormModal from "../formmodal/FormModal";

const FreeWorkshop = () => {
  const [formModalShow, setFormModalShow] = useState(false);
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  return (
    <>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Workshop"}
        description={"Unlock Your Potential: Join Now!"}
      />

      <div className="row g-0 mt-5 justify-content-center mt-5 pt-5">
        <div className="col-xl-10 col-lg-11 col-12 mt-md-5">
          <div className="row g-0 mt-lg-5">
            <div className="col-lg-6 col-12 d-flex justify-content-lg-end justify-content-center">
              <img src={workshopImg} className="w-100 meeting_img px-4 px-md-0" alt="" />
            </div>
            <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center workshop_card_container">
              <div className="workshop_text workshop_background my-3 d-flex justify-content-center align-items-center px-md-5 mx-md-5 mx-4 mx-lg-0">
                <div className="px-xxl-5 mx-5">
                  <div className="DM_Serif_Text workshop_title text-center text-lg-start ">
                    Experience The Workshop for Free
                  </div>
                  <div className="my-4 me-xl-5 workshop_paragraph">
                    Discover the realm of Graphic Design, Manual Testing and Full Stack Development in our free workshop. Join experts as they unveil the art of coding, design, and innovation. Whether you're a beginner or an enthusiast, dive into live demos, real projects, and a world of possibilities. Reserve your spot today, next workshop starting on 29th March 2024, from 9:00AM To 1:00PM.
                  </div>
                  <div className="text-center text-lg-start mt-sm-5 pb-4 pb-sm-0 mt-lg-0">
                    <button
                      className="workshop_joinbutton py-3 px-5"
                      onClick={() => handleFormModalShow()}
                    >
                      Join Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeWorkshop;
