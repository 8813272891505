import React, { useState } from "react";
import ArrowIcon from "../../assets/Group.png";
import weekimg from "../../assets/week list.png";
import leftarrow from "../../assets/Group 23.png";
import rightarrow from "../../assets/Group 26.png";
import { array } from "yup";
import "./weekTimeline.css";
import useScreenSize from "../helper/UserScreenSize";

const BackEndWeekTimeLine = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const width = useScreenSize();
  const weekList = [
    {
      value: 1,
      title: "Week 1",
      session1: "Introduction to Back-End Development",
      session2: "Overview of Back-End Development",
      session3: "Role of a Back-End Developer",
      session4: "Introduction to Server-Side Programming",

    },
    {
      value: 2,
      title: "Week 2",
      session1: "Setting Up the Development Environment",
      session2: "Installing and Configuring Node.js",
      session3: "Introduction to Express.js Framework",
      session4: "Building and Running a Simple Server"
    },
    {
      value: 3,
      title: "Week 3",
      session1: "Building RESTful APIs",
      session2: "Understanding REST Architecture",
      session3: "Creating Routes and Handling Request",
      session4: "Sending JSON Responses"
    },

    {
      value: 4,
      title: "Week 4",
      session1: "Data Storage and Databases",
      session2: "Introduction to Databases (SQL and NoSQL)",
      session3: "Database Design Principles",
      session4: "Connecting to and Querying Databases",
    },
    {
      value: 5,
      title: "Week 5",
      session1: "Authentication and Authorization",
      session2: "User Authentication Strategies",
      session3: "Managing User Sessions and Tokens",
      session4: "Role-Based Access Control"
    },
    {
      value: 6,
      title: "Week 6",
      session1: "Middleware and Request Processing",
      session2: "Working with Middleware Functions",
      session3: "Parsing Request Data",
      session4: "Handling Errors"
    },
    {
      value: 7,
      title: "Week 7",
      session1: "Advanced Database Operations",
      session2: "Data Validation and Sanitization",
      session3: "Transactions and Batch Operations",
      session4: "Database Security Best Practices"
    },
    {
      value: 8,
      title: "Week 8",
      session1: "Introduction to Front-End Integration",
      session2: "Integrating Front-End and Back-End",
      session3: "REST API Consumption from Front-End",
    },
    {
      value: 9,
      title: "Week 9",
      session1: "Introduction to Cloud Services",
      session2: "Overview of Cloud Platforms (e.g., AWS, Azure)",
      session3: "Deploying Back-End Applications in the Cloud",
    },
    {
      value: 10,
      title: "Week 10",
      session1: "Real-Time Web Applications",
      session2: "WebSockets and Real-Time Communication",
      session3: "Implementing Real-Time Features",
    },
    {
      value: 11,
      title: "Week 11",
      session1: "Building RESTful APIs with Express.js",
      session2: "Advanced Routing and Middleware",
      session3: "Versioning APIs",
      session4: "API Documentation"
    },
    {
      value: 12,
      title: "Week 12",
      session1: "Security and Authentication in Depth",
      session2: "Secure Password Hashing",
      session3: "OAuth and Social Media Authentication",
      session4: "Web Application Security Best Practices"
    },
    {
      value: 13,
      title: "Week 13",
      session1: "Scaling and Performance Optimization",
      session2: "Load Balancing",
      session3: "Caching Strategies",
      session4: "Monitoring and Performance Tuning",
    },
    {
      value: 14,
      title: "Week 14",
      session1: "Working with External Services",
      session2: "Integrating Third-Party APIs",
      session3: "Payment Gateway Integration",
      session4: "Email and Notification Services",

    },
    {
      value: 15,
      title: "Week 15",
      session1: "Final Project Development",
      session2: "Individual or Group Back-End Projects",
      session3: "Integrating Back-End with Front-End (Full Stack)",
      session4: "Testing and Debugging",

    },
    {
      value: 16,
      title: "Week 16",
      session1: "Project Completion and Deployment",
      session2: "Finalise and present Back-End Projects",
    },
  ];

  return (
    <>
      <div className=" d-flex justify-content-center mt-5 mx-auto">
        <div className="col-lg-10 col-12 mt-5" style={{overflowX: width < 768 && "scroll"}}>
          <div className="row g-0" style={{width: width < 768 && "700px", overflowX: width < 768 && "scroll"}}>
            <div className="col-lg-3 col-12 px-4 time_line border_radus_7px">
              <div className="p-2 fs_30 DM_Serif_Text black_color pb-4 text-nowrap">Time Line</div>
              <div className="custom-container d-lg-block d-none">
                {weekList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex open_sans fs_18 fw-bold mt-5 cursor_pointer text-nowrap"
                        onClick={() => setCurrentWeek(item?.value)}
                      >
                        {item?.value === currentWeek ? (
                          <>
                            <img className="" src={ArrowIcon} height={"20px"} width={"20px"} />
                          </>
                        ) : null}
                        <span className="ps-3">{item?.title}</span>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="horizontal-container d-lg-none d-block">
                <div className="d-flex gap-5">
                  {weekList?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="open_sans fs_15 mt-5 cursor_pointer text-nowrap"
                          onClick={() => setCurrentWeek(item?.value)}
                        >
                          <button className={`${item?.value === currentWeek ? "redbtn px-3 p-2" : "nobtn px-3 p-2"}`}>
                            <span>{item?.title}</span>
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 px-3 mt-5 mt-lg-0">
              <div className=" d-flex justify-content-between ms-lg-5">
                <div className="p-2 fs_xs_24 fs_32 DM_Serif_Text black_color">Week {currentWeek}</div>
                <div className="d-lg-none d-lg-block">
                  <div className="d-flex justify-content-end align-items-center pe-2 mt-4">
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre - 1)}
                      onClick={() => {
                        if (currentWeek > 1) {
                          setCurrentWeek((pre) => pre - 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek < 16) {
                            horizontalContainer.scrollLeft -= 100;
                          }
                        }
                      }}

                      className="scrollbtns" disabled={currentWeek === 1}>
                      <img className=" w-50" src={leftarrow} />
                    </button>
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre + 1)} 
                      onClick={() => {
                        if (currentWeek < 16) {
                          setCurrentWeek((pre) => pre + 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek >= 1) {
                            horizontalContainer.scrollLeft += 100;
                          }
                        }
                      }}
                      className="scrollbtns" disabled={currentWeek === 16}>
                      <img className=" w-50" src={rightarrow} />
                    </button>
                  </div>
                </div>
              </div>
              {weekList
                ?.filter((item) => item?.value === currentWeek)
                .map((data) => {
                  return (
                      <div className=" position-relative ms-lg-5">
                        <div className="mt-3">
                          <img className="d-none d-md-block w-100 weekimg" height="500px" src={weekimg} />
                          <img className="d-block d-sm-none w-100 weekimg" height="350px" src={weekimg} />
                          <img className="d-none d-sm-block d-md-none w-100 weekimg" height="400px" src={weekimg} />
                        </div>

                        {
                              <div className=" text-center">
                                {data.session1 && <div className="session_11 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session1}
                                </div>}
                                {data?.session2 && <div className="session_22 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session2}
                                </div>}
                                {data?.session3 && <div className="session_33 fs_sm_10 fs_14 open_sans px-3 fw_600 me-3 me-sm-0">
                                  {data.session3}
                                </div>}
                                {data.session4 && <div className="session_44 fs_sm_10 fs_14 open_sans px-3 fw_600 me-1 me-sm-0">
                                  {data.session4}
                                </div>}
                              </div>
                        }
                      </div>
                  );
                })}
              <div className="d-none d-lg-block">
                <div className="d-flex justify-content-end pe-2 mt-5 gap-2">
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre - 1)}
                    onClick={() => {
                      if (currentWeek > 1) {
                        setCurrentWeek((pre) => pre - 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek <= 10) {
                          customContainer.scrollTop -= 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 1}>
                    <img className=" w-75" src={leftarrow} />
                  </button>
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre + 1)}
                    onClick={() => {
                      if (currentWeek < 16) {
                        setCurrentWeek((pre) => pre + 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek >= 7) {
                          customContainer.scrollTop += 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 16}>
                    <img className=" w-75" src={rightarrow} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackEndWeekTimeLine;
