import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Almnd from "../modules/Almnd/almnd";
import { ToastContainer } from "react-toastify";

import PrivacyPolicy from "../modules/static-pages/privacy-policy";
import FullStackDevCourse from "../modules/Full-stack-dev-course/full-stack-dev-course";
import FrontendDevCourse from "../modules/Front-end-dev-course/frontend-dev-course";
import BackendDevCourse from "../modules/Back-end-dev-course/backend-dev-course";
import MobileDevCourse from "../modules/Mobile-dev-course/mobile-dev-course";
import WorkShop from "../modules/work-shop/work-shop";
import ScrollToTop from "../components/helper/scroll-to-top";
import ManualTesting from "../modules/manualTesting";
const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Almnd />} />
        <Route
          path="/full-stack-development-course"
          element={<FullStackDevCourse />}
        />
        <Route
          path="/front-end-development-course"
          element={<FrontendDevCourse />}
        />
        <Route
          path="/back-end-development-course"
          element={<BackendDevCourse />}
        />
        <Route
          path="/mobile-development-course"
          element={<MobileDevCourse />}
        />
        <Route path="/work-shop" element={<WorkShop />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/manual-testing" element={<ManualTesting />} />
      </Routes>
      <ScrollToTop />
    </Router>
  );
};

export default Index;
