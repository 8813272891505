import React from 'react'
import "../style.css";
import commenticon from "../../assets/comment-dots 1.png";
import headerimg from "../../assets/headerimg.png";
import headerimg2 from "../../assets/headerimg2.png";
import arrow from "../../assets/Line 2.png";
import quotes from "../../assets/“.png";
import "../Curriculum/curriculum.css"
// import MaskGroupBg from "../../assets/Mask-group.png"
const Impressivestats = ({impressivestats}) => {
    const cardDetails = [
        {
            heading: "300 +",
            value:
                "Students Hired",
            background: "#0F42DB",
        },
        {
            heading: "20 +",
            value:
                "Hiring Partners",
            background: "#FF2852",
        },
        {
            heading: "8 Lakh",
            value:
                "Average Salary",
            background: "white",
        },
    ];
    return (
        <>
        <div className='d-none d-sm-block mt-5 pt-5'>
            <div className='row g-0 d-flex justify-content-center background mt-5 pt-3 g-0 py-5 mask_bg'>
                <div className='col-lg-6 col-12 hiredstudents'>
                    <div className="mt-5 ps-sm-5 d-none d-sm-block">
                        <div className='d-flex justify-content-center gap-lg-4 me-5 pe-5 me-lg-0 pe-lg-0'>
                            <span><img className='quotes' width="50px" src={quotes} /></span>
                            <span className='DM_Serif_Text impressive mt-md-5 fs_50 mt-5'>Impressive Stats</span>
                        </div>
                    </div>
                </div>
                
                <div className='col-lg-6 col-12 hiredstudents'>
                    <div className="d-flex justify-content-center row g-4 gy-xl-2 gy-lg-5 justify-content-around justify-content-sm-center align-items-center col-12 col-xl-11 col-xxl-10">
                        <div className=" d-flex justify-content-center">
                            <div className="d-lg-flex gap-5 ms-4">
                                <div className="row justify-content-center g-0 gx-0 mt-4 text-nowrap">
                                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                                        <div className=" d-flex justify-content-center me-5">
                                            <div className=" d-flex gap-2 text-center justify-content-center">
                                                <div className='colorCard align-content-center'>
                                                    <div className='DM_Serif_Text hirings'>{impressivestats?.no_of_stud_value}</div>
                                                    <div className='open_sans partnerss'>{impressivestats?.no_of_stud_label}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                                        <div className=" d-flex justify-content-center me-3">
                                            <div className=" d-flex gap-2 text-center justify-content-center">
                                                <div className='colorCards align-content-center'>
                                                    <div className='DM_Serif_Text hirings'>{impressivestats?.hiring_partners_value}</div>
                                                    <div className='open_sans partnerss'>{impressivestats?.hiring_partners_label}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="  col-sm-4 col-12 mt-3 ps-3">
                                        <div className=" d-flex justify-content-center">
                                            <div className=" d-flex gap-2 text-center justify-content-center">
                                                <div className=' colorCardss align-items-center'>
                                                    <div className='DM_Serif_Text hiring '>{impressivestats?.avg_sal_value}</div>
                                                    <div className='open_sans partners '>{impressivestats?.avg_sal_label}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='d-block d-sm-none background mask_bg'>
                <div className='row mx-3  py-5'>
                    <div className='d-flex justify-content-center mb-4 pe-5 '>
                        <span className=''>
                            <img className='' src={quotes} width={40} alt="quotes-icon"/>
                        </span>
                        <div className='text-white fs_40 DM_Serif_Text mt-4'>Impressive Stats</div>
                    </div>
                    <div className='col-6 text-white'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <div className='d-flex justify-content-center align-items-center students_hired w-100 border_radus_7px'>
                                <div>
                                   <div className='text-center fs_34 DM_Serif_Text letter_space fw_500'>{impressivestats?.no_of_stud_value}</div>
                                    <div className='fs_16 fw_500 open_sans'>{impressivestats?.no_of_stud_label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 text-white'>
                    <div className='d-flex justify-content-start align-items-center'>
                            <div className='d-flex justify-content-center align-items-center hiring_partners w-100 border_radus_7px'>
                                <div>
                                   <div className='text-center fs_34 DM_Serif_Text letter_space fw_500'>{impressivestats?.hiring_partners_value}</div>
                                    <div className='fs_16 fw_500 open_sans'>{impressivestats?.hiring_partners_label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mt-4 text-white'>
                    <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex justify-content-center align-items-center average_salary w-100 border_radus_7px'>
                                <div>
                                   <div className='color_blue fs_34 DM_Serif_Text letter_space fw_500 text-center'>{impressivestats?.avg_sal_value}</div>
                                    <div className='color_blue fs_16 fw_500 open_sans'>{impressivestats?.avg_sal_label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default Impressivestats