import React, { useEffect, useState } from "react";
import "../style.css";
import "./curriculum.css";
import commenticon from "../../assets/comment-dots 1.png";
import FormModal from "../formmodal/FormModal";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import "../Curriculum/curriculum.css";
import { useLocation, useNavigate } from "react-router";
import FullStackImg from "../../assets/Full-stack-development-img.png";
import FrontEndImg from "../../assets/Front-end-dev-img.png";
import BackEndImg from "../../assets/back-end-dev-img.png";
import MobileAppImg from "../../assets/mobile-app-img.png";
import WorkShopImg from "../../assets/work-shop-full-img.png";
import CalanderIcon from "../../assets/icons/calander-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorToast from "../ErrorToast";
import SuccessToast from "../SuccessToast";
import axios from "axios";
import RightIcon from "../../assets/icons/right-mark-icon.svg";
const WorkShopCurriculum = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const [formModalShow, setFormModalShow] = useState(false);
  const [error, setError] = useState("");
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  const coursesDetails = [
    {
      courseImg: FullStackImg,
      courseTitle: (
        <span>
          Full-Stack Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Ofline",
      weeks: 4,
      courseType: "full_stack",
    },
    {
      courseImg: FrontEndImg,
      courseTitle: (
        <span>
          Front-End Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Ofline",
      weeks: 4,
      courseType: "front_end",
    },
    {
      courseImg: BackEndImg,
      courseTitle: (
        <span>
          Back-End Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Ofline",
      weeks: 4,
      courseType: "back_end",
    },
    {
      courseImg: MobileAppImg,
      courseTitle: (
        <span>
          Mobile App Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Ofline",
      weeks: 4,
      courseType: "mobile",
    },
  ];
  const learn = [
    {
      tital: "React.js Basics",
      label:
        "Gain a solid understanding of the core concepts of React.js, including components, state, and props.",
    },
    {
      tital: "Building a Tic-Tac-Toe Game",
      label:
        "Learn how to create a complete tic-tac-toe game using React.js, putting your knowledge into practice.",
    },
    {
      tital: "Interactivity",
      label:
        "Explore how to make your game interactive and responsive, enhancing the user experience.",
    },
    {
      tital: "Best Practices",
      label:
        "Discover industry best practices and coding standards for React.js development.",
    },
    {
      tital: "Q&A Session",
      label:
        "Get your questions answered in a dedicated Q&A session with Mr. Raghuram..",
    },
  ];
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else {
      return "home";
    }
  };

  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActiveTab(newActiveTab);
  }, [router.pathname]);
  // console.log(router.pathname.startsWith("/full-stack-development-course"), "router");
  console.log(activeTabFn(), activeTab, "router");
  const viewCourseFn = (courseType) => {
    if (courseType === "full_stack") {
      navigate("/full-stack-development-course");
    } else if (courseType === "front_end") {
      navigate("/front-end-development-course");
    } else if (courseType === "back_end") {
      navigate("/back-end-development-course");
    } else if (courseType === "mobile") {
      navigate("/mobile-development-course");
    }
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const workshopApi = (values) => {
    axios
      .post("https://api.almnd.in/api/attend-workshop-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
      })
      .then((result) => {
        console.log(result.data, "jhvhjchchjch");
        SuccessToast(result?.data?.message, "jgcghcjgcgkyc");
        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("!Required"),
      email: Yup.string().required("!Required"),
      phone: Yup.string()
        .required("!Required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Minimum requires 10 numbers")
        .max(10, "Maximum requires 10 numbers only"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      workshopApi(values);
      resetForm();
      setError(null);
    },
  });
  return (
    <>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Curriculum"}
        description={"Unlock Your Potential: Download Now!"}
      />
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Free Workshop"}
        description={"Basics of React.js: Join Almnd's Exclusive Webinar!"}
      />
      <div className="mt-sm-5 pt-5 pb-5">
        <div className="col-xl-11 col-12 pb-5 pb-sm-0 mt-5">
          <div className="d-lg-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-12">
              <div className="px-lg-5 mx-5 ms-lg-5 me-lg-0">
                <div className="ms-lg-5 ps-xl-5">
                  <div
                    className="d-flex color_bg_FF28521A px-2 py-1 border_radus_5px"
                    style={{ width: "fit-content" }}
                  >
                    <div>
                      <img src={CalanderIcon} />
                    </div>
                    <div className="open_sans commenticon fs_xs_14 fs_15 fs_sm_12 ms-3">
                    11th November 2023, from 9:00AM To 1:00PM.
                    </div>
                  </div>
                </div>
                <div
                  className="fs_lg_28 fs_36 letter_spacing fw_600 DM_Serif_Text mt-3 ms-lg-5 ps-xl-5"
                  // style={{ maxWidth: "500px" }}
                >
                  Basics of React.js: Join Almnd's Exclusive Webinar
                </div>
                <div className="embark open_sans mt-4 ms-lg-5 ps-xl-5 col-8">
                Join us for an engaging and informative webinar that will kickstart your journey into the world of React.js development.
                </div>
                <div className="d-flex gap-5 mt-4 ms-lg-5 ps-xl-5">
                  <button
                    className="enrollblubtn px-4 py-2 "
                    onClick={() => handleEnrollModalShow()}
                  >
                    Reserve your spot now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-5 mt-lg-0 pt-5 pt-lg-0 d-flex justify-content-center justify-content-lg-start justify-content-xl-center">
              <img className="w-75" src={WorkShopImg} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
        <div className="col-lg-10">
          <div className="row justify-content-center justify-content-md-start g-0 mt-sm-5 pt-sm-5 mx-5 mx-lg-0">
            <div className="col-md-6 col-xxl-5 col-12 mt-5 pt-5">
              <div style={{ maxWidth: "60rem" }}>
              <h1 className="DM_Serif_Text fw_500 fs_24">Join the Workshop</h1>
              <label className="color_969696 fs_15 mt-3 me-5">Meet Mr. Raghuram, a seasoned React.js programmer with over 8 years of hands-on experience in building real-world applications</label>
              <form className="me-md-5 pe-md-5 mt-4" onSubmit={formik.handleSubmit} >
                <div>
                  <lable
                    className={`open_sans fs_xs_14 fs_15 fw_600 ${
                      formik.touched.name && formik.errors.name
                        ? " errorcolor"
                        : ""
                    }`}
                  >
                    Name
                  </lable>
                  <input
                    className={`w-100 inputfields p-3 ps-2 ${
                      formik.touched.name && formik.errors.name
                        ? "inputfieldserror"
                        : ""
                    }`}
                    type="text"
                    placeholder="Enter your name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="errorcolor open_sans ps-2">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <lable
                    className={`fs_xs_14 fs_15 fw_600 open_sans ${
                      formik.touched.email && formik.errors.email
                        ? " errorcolor"
                        : ""
                    }`}
                  >
                    Email Address
                  </lable>
                  <input
                    className={`w-100 inputfields p-3 ps-2 ${
                      formik.touched.email && formik.errors.email
                        ? "inputfieldserror"
                        : ""
                    }`}
                    type="email"
                    placeholder="Enter your email address"
                    id="email"
                    email="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errorcolor open_sans ps-2">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <lable
                    className={`fs_xs_14 fs_15 fw_600 open_sans ${
                      formik.touched.phone && formik.errors.phone
                        ? " errorcolor"
                        : ""
                    }`}
                  >
                    Phone Number
                  </lable>
                  <input
                    className={`w-100 inputfields p-3 ps-2 ${
                      formik.touched.phone && formik.errors.phone
                        ? "inputfieldserror"
                        : ""
                    }`}
                    type="text"
                    placeholder="Enter your phone number"
                    maxlength="10"
                    pattern="\d{10}"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="errorcolor open_sans ps-2">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
                <div>{error}</div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className={`w-100 px-5 p-3 fs_16 submitbtn`}
                  >
                    Join
                  </button>
                </div>
              </form>
              </div>
            </div>
            <div className="col-md-6 col-xxl-6 col-12 mt-5 pt-4 d-flex justify-content-xxl-center">
              <div className="ms-md-5 ps-xxl-5 col-xl-8">
                <h1 className="DM_Serif_Text fs_xs_24 fs_sm_28 fs_36">Details</h1>
                <div className="fs_16 fs_xs_14 open_sans color_969696">
                  Date and Time: The webinar will be held on 11th November, from 9:00AM To 1:30PM.
                </div>
                <div className="fs_16 fs_xs_14 mt-3 open_sans color_969696">
                  Location: 2nd Floor, Emirates Arcade, 80 Feet Rd, behind Water Tank, Manchegowdana koppalu, II Stage, Vijayanagar, Mysuru, Karnataka 570017
                </div>
                <div className="fs_16 fs_xs_14 mt-3 open_sans color_969696">
                  Host: Meet Mr. Raghuram, a seasoned React.js programmer with over
                  8 years of hands-on experience in building real-world
                  applications
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        
        {/* <div className="row g-0 mt-sm-5 pt-5 mx-5 pb-sm-5">
          <div className="col-lg-6 col-12 clearfix mt-5 pt-5 d-none d-lg-block"></div>
          <div className="col-lg-5 col-12">
            <h1 className="DM_Serif_Text fs_sm_24 fs_36 mt-lg-5 pt-5">
              What You Will Learn
            </h1>
            <label className="fs_14 color_969696 mt-3">
              In this webinar, you will embark on a journey to understand the
              fundamentals of React.js through a practical example of building a
              tic-tac-toe game. Mr. Raghuram, our expert host, will guide you
              through the following key topics:
            </label>

            <div className="mt-5">
            {learn?.map((item) => {
              return (
                <>
                  <div className="d-flex mt-4 color_bg_F5F5F580 px-3 py-2 border_radus_7px">
                    <div>
                      <img src={RightIcon} alt="RightIcon" />
                    </div>
                    <div className="ms-2 mt-1">
                        <strong className="fs_15">{item?.tital} :</strong>
                        <span className="fs_15 ms-2">{item?.label}</span>
                    </div>
                  </div>
                </>
              );
            })}
            </div>
          </div>
        </div> */}
        <div className="d-flex justify-content-center justify-content-xl-end col-xl-11 mt-5 mx-4 mx-sm-0">
        <div className="col-xl-5 col-md-7 col-sm-10 col-12 ">
          <div className="">
          <h1 className="DM_Serif_Text fs_sm_24 fs_36 mt-lg-5 pt-5 text-center text-xl-start">
              What You Will Learn
            </h1>
            <label className="fs_14 color_969696 mt-3">
              In this webinar, you will embark on a journey to understand the
              fundamentals of React.js through a practical example of building a
              tic-tac-toe game. Mr. Raghuram, our expert host, will guide you
              through the following key topics:
            </label>

            <div className="mt-5">
            {learn?.map((item) => {
              return (
                <>
                  <div className="d-flex mt-4 color_bg_F5F5F580 px-3 py-2 border_radus_7px">
                    <div>
                      <img src={RightIcon} alt="RightIcon" />
                    </div>
                    <div className="ms-2 mt-1">
                        <strong className="fs_15">{item?.tital} :</strong>
                        <span className="fs_15 ms-2">{item?.label}</span>
                    </div>
                  </div>
                </>
              );
            })}
            </div>
          </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default WorkShopCurriculum;
