import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Impressivestats from "../../components/ImpressiveStats/Impressivestats";
import Footer from "../../components/Footer/Footer";
import Contactus from "../../components/ContactUs/contactus";
import PaymentCards from "../../components/paymentCards/PaymentCards";
import WhatMore from "../../components/whatmore/WhatMore";
import DwnldSyllabus from "../../components/dwnldsyllabus/DwnldSyllabus";
import TechnologiesTaught from "../../components/technologiestaught/TechnologiesTaught";
import Partners from "../../components/partners/Partners";
import EmbarkLearning from "../../components/embarkLearning/EmbarkLearning";
import FreeWorkshop from "../../components/freeWorkshop/FreeWorkshop";
import FrontEntDevCurriculum from "../../components/Curriculum/front-end-curriculum";
import FrontEndFaq from "../../components/Faqs/front-end-faq";
import FrontEndWeekTimeLine from "../../components/weektimeline/front-end-time-line";
import FrontEndRolesCanFit from "../../components/roleCanFit/front-end-dev-roles";
import GoToTop from "../../components/helper/go-to-top";
const FrontendDevCourse = () => {
  const [isFrontEndClicked, setIsFrontEndClicked] = useState(false);
  const sectionRefs = {
    ContactUs: useRef(null),
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isFrontEndClicked]);
  const impressivestats = {
    no_of_stud_label: "No. of Students",
    no_of_stud_value: 252,
    hiring_partners_label: "Hiring Partners",
    hiring_partners_value: 112,
    avg_sal_label: "Average Salary",
    avg_sal_value: "10L",
  };
  return (
    <div>
      <Navbar
        isActiveTab={isFrontEndClicked}
        setIsActiveTab={setIsFrontEndClicked}
        scrollToSection={scrollToSection}
      />
      <div className="mt-5 pt-1">
        <FrontEntDevCurriculum />
      </div>
      <Impressivestats impressivestats={impressivestats} />
      <EmbarkLearning />
      <FreeWorkshop />
      <TechnologiesTaught />
      <div className="mt-5 pt-lg-5 pt-md-3">
        <Partners />
      </div>
      <div ref={sectionRefs.DwnldSyllabus}>
        <DwnldSyllabus />
      </div>
      <FrontEndWeekTimeLine />
      <WhatMore />
      <FrontEndRolesCanFit />
      <div className="pt-md-5 mb-5" ref={sectionRefs.PaymentCards}>
        <PaymentCards />
      </div>
      <div className="pt-5">
        <FrontEndFaq scrollToSection={scrollToSection} />
      </div>
      <div className="mt-sm-5 pt-sm-5" ref={sectionRefs.ContactUs}>
        <Contactus />
      </div>
      <Footer scrollToSection={scrollToSection} />
      <GoToTop />
    </div>
  );
};

export default FrontendDevCourse;
