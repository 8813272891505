import "../style.css";
import Link, { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../assets/almndnavicon.png";
import menuIcon from "../../assets/icons/menu-line.png";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import MenuLine from "../../assets/icons/menu-line.png";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import SubscriptionModal from "../formmodal/subscriptionModal";
// import { Header } from 'antd/es/layout/layout';

const MainNavbar = ({
  scrollToSection,
  isActiveTab,
  handleManualTestingClick,
  setIsActiveTab = () => {},
}) => {
  const navigate = useNavigate();
  const router = useLocation();
  const [activetab, setActivetab] = useState("");
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const toggleMenu = () => {
    setShowMediaIcons(!showMediaIcons);
  };
  const [toggle, setToggle] = useState(false);

  // const DropdownMenu = ({ onSelect }) => {
  //   return (
  //     <div className="" style={{ backgroundColor: '#ff2852', border: '1px solid', borderColor: 'red', color: 'white', borderRadius: '10px', margin: '10px' }}>
  //       <ul style={{
  //         listStyle: "none",
  //         padding: 0,
  //         margin: 0
  //       }}>
  //         <li id="list-1"
  //           onMouseEnter={() => { document.getElementById("list-1").style.color = "black" }}
  //           onMouseLeave={() => { document.getElementById("list-1").style.color = "white" }}
  //           style={{ padding: "5px 14px" }}
  //           onClick={() => { window.open("https://ff-e-commerce.s3.ap-south-1.amazonaws.com/Almnd+Course+plan.pdf", "_blank"); }}>View Details</li>
  //         <li id="list"
  //           onMouseEnter={() => { document.getElementById("list").style.color = "black" }}
  //           onMouseLeave={() => { document.getElementById("list").style.color = "white" }}
  //           style={{ padding: "5px 14px" }}
  //           onClick={() => { handleEnrollModalShow(); setActivetab("manual_testing"); setIsDropdownOpen(false); setSelectedOption(true); }}>Enroll now</li>
  //       </ul>
  //     </div >
  //   );
  // };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);

  const handleHoverEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleHoverLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleLocalManualTestingClick = (option) => {
    setSelectedOption(true);
    setIsDropdownOpen(false);
    if (typeof handleManualTestingClick === "function") {
      handleManualTestingClick();
    } else {
      console.error(
        "handleManualTestingClick is not a function jhcdushfuicisdegf"
      );
    }
  };
  console.log(typeof handleManualTestingClick, "jhcdushfuicisdegf");
  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
    setSelectedOption(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  const closeNavbar = () => {
    setToggle(false);
  };

  const closeMobileMenu = () => {
    setToggle(false);
  };
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else if (router.pathname.startsWith("/front-end-development-course")) {
      return "front_end";
    } else if (router.pathname.startsWith("/back-end-development-course")) {
      return "back_end";
    } else if (router.pathname.startsWith("/mobile-development-course")) {
      return "mobile";
    } else if (router.pathname.startsWith("/work-shop")) {
      return "work_shop";
    } else if (router.pathname.startsWith("/manual-testing")) {
      return "manual_testing";
    } else if (router.pathname.startsWith("/")) {
      return "home";
    } else if (router.pathname.startsWith("")) {
      return "contact_us";
    }
    // else{
    //   return "contact_us"
    // }
  };

  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActivetab(newActiveTab);
  }, [router.pathname]);

  useEffect(() => {
    if (activetab !== "contact_us") {
      window.scrollTo(0, 0);
    }
  }, [activetab]);

  console.log(router.pathname, activetab, "pathname");
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  const handleSubscriptionModalClose = () => {
    setSubscriptionModalShow(false);
  };

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem("hasModalBeenShown");

    const showSubscriptionModal = () => {
      setSubscriptionModalShow(true);
      sessionStorage.setItem("hasModalBeenShown", true);
    };

    if (!hasModalBeenShown) {
      showSubscriptionModal();
    } else {
      const intervalCall = setInterval(() => {
        console.log("running");
        showSubscriptionModal();
      }, 60 * 60 * 1000);

      return () => {
        clearInterval(intervalCall);
      };
    }
  }, []);
  return (
    <>
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Enroll now"}
        description={"Unlock Your Potential: Enroll Now!"}
        is_from_testing={selectedOption}
      />

      <div className=" mb-3 d-none d-xxl-block navbar-sticky Navbar_Bg">
        <div className="d-flex justify-content-between">
          <div className="d-flex ">
            <div className=" d-flex logo">
              <div
                className="cursor_pointer"
                onClick={() => {
                  navigate("/");
                  setActivetab("home");
                }}
              >
                <img
                  width="200px"
                  className="ms-5 ps-5"
                  src={logo}
                  alt="logo"
                />
              </div>
              <div className="d-flex ms-lg-5 ms-4 text-nowrap cursor_pointer">
                {/* <div className="actions open_sans">About Us</div> */}
                <div
                  // onClick={() => scrollToSection("Curriculum")}
                  onClick={() => {
                    navigate("/");
                    setActivetab("home");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "home"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Home
                </div>
                <div
                  onClick={() => {
                    navigate("/full-stack-development-course");
                    setActivetab("full_stack");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "full_stack"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  {/* Journey */}
                  Full-Stack
                </div>
                <div
                  onClick={() => {
                    navigate("/front-end-development-course");
                    setActivetab("front_end");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "front_end"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Front-End
                </div>
                <div
                  onClick={() => {
                    navigate("/back-end-development-course");
                    setActivetab("back_end");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "back_end"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Back-End
                </div>
                <div
                  onClick={() => {
                    navigate("/mobile-development-course");
                    setActivetab("mobile");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "mobile"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Mobile Application
                </div>
                <div
                  onClick={() => {
                    window.open("https://chidiyaghar.in", "_blank");
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "graphic"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Graphic Design
                </div>

                <div
                  onClick={() => {
                    navigate("/manual-testing");
                    setActivetab("manual_testing");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "manual_testing"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Manual Testing
                  {/* <div className="position-fixed">
                    {isDropdownOpen && (
                      // <DropdownMenu onSelect={handleOptionSelect} />
                      <div
                        className=""
                        style={{
                          backgroundColor: "#ff2852",
                          border: "1px solid",
                          borderColor: "red",
                          color: "white",
                          borderRadius: "10px",
                          margin: "10px",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <li
                            id="list-1"
                            onMouseEnter={() => {
                              document.getElementById("list-1").style.color =
                                "black";
                            }}
                            onMouseLeave={() => {
                              document.getElementById("list-1").style.color =
                                "white";
                            }}
                            style={{ padding: "5px 14px" }}
                            onClick={handleLocalManualTestingClick}
                          >
                            View Details
                          </li>
                          <li
                            id="list"
                            onMouseEnter={() => {
                              document.getElementById("list").style.color =
                                "black";
                            }}
                            onMouseLeave={() => {
                              document.getElementById("list").style.color =
                                "white";
                            }}
                            style={{ padding: "5px 14px" }}
                            onClick={() => {
                              handleEnrollModalShow();
                              setActivetab("manual_testing");
                              setIsDropdownOpen(false);
                              setSelectedOption(true);
                            }}
                          >
                            Enroll now
                          </li>
                        </ul>
                      </div>
                    )}
                  </div> */}
                </div>

                <div
                  onClick={() => {
                    scrollToSection("ContactUs");
                    setActivetab("contact_us");
                  }}
                  className={`open_sans ms-lg-5 ms-2 fs_17 ${
                    activetab === "contact_us"
                      ? "text_underline_decoration color_FF2852 fw_600"
                      : "color_black fw_500"
                  }`}
                >
                  Contact Us
                </div>
              </div>
            </div>
          </div>
          <div className="me-lg-5 pe-5 d-flex align-items-center">
            <div
              className={`${
                activetab === "work_shop" ? "color_bg_0F42DB" : "color_bg_white"
              } boredr_1px_0F42DB me-4 px-4 py-2 border_radus_5px cursor_pointer`}
              onClick={() => navigate("/work-shop")}
            >
              <button
                className={`border-0 text-center fw_500 fs_17 ${
                  activetab === "work_shop" ? "text-white" : "color_0F42DB"
                }`}
                style={{ backgroundColor: "transparent" }}
              >
                React Workshop
              </button>
            </div>

            <button
              className="enrollbtn px-5 py-2 fs_17 fw_500"
              onClick={() => handleEnrollModalShow()}
            >
              Enroll Now
            </button>
          </div>
        </div>
      </div>

      <div className="d-block d-xxl-none navbar-sticky Navbar_Bg">
        <div className="d-flex justify-content-between ">
          <div
            className="cursor_pointer"
            onClick={() => {
              navigate("/");
              setActivetab("home");
            }}
          >
            <img
              width="150px"
              className="ms-sm-5 ps-sm-5 ms-4"
              src={logo}
              alt="logo"
            />
          </div>
          <div>
            <div className=" Hamburger_Menu_Icon me-sm-5 pe-sm-5 me-4 cursor_pointer">
              <img
                src={menuIcon}
                className="pt-2"
                alt=""
                onClick={() => setToggle(!toggle)}
              />
            </div>
          </div>
        </div>
        {toggle && (
          <>
            <div className="d-flex justify-content-center Toggle_Navbar ">
              <div className="text-nowrap">
                {/* Scroll to the top of the section when a mobile menu item is clicked */}
                <div
                  className="actions open_sans mt-5 pb-4"
                  onClick={() => {
                    closeMobileMenu();
                    navigate("/");
                    setActivetab("home");
                    setToggle(!toggle);
                  }}
                >
                  <label
                    className={`open_sans fs_18 cursor_pointer ${
                      activetab === "home"
                        ? "text_underline_decoration color_FF2852 fw_500"
                        : "color_black"
                    }`}
                  >
                    Home
                  </label>
                </div>
                <div
                  onClick={() => {
                    navigate("/full-stack-development-course");
                    setActivetab("full_stack");
                    setToggle(!toggle);
                  }}
                  className="open_sans fs_18 color_black mb-4"
                >
                  <label
                    className={`open_sans fs_18 cursor_pointer ${
                      activetab === "full_stack"
                        ? "text_underline_decoration color_FF2852 fw_500"
                        : "color_black"
                    }`}
                  >
                    Full-Stack
                  </label>
                </div>
                <div
                  onClick={() => {
                    navigate("/front-end-development-course");
                    setActivetab("front_end");
                    setToggle(!toggle);
                  }}
                  className="open_sans fs_18 color_black pb-4"
                >
                  <label
                    className={`open_sans fs_18 cursor_pointer ${
                      activetab === "front_end"
                        ? "text_underline_decoration color_FF2852 fw_500"
                        : "color_black"
                    }`}
                  >
                    Front-End
                  </label>
                </div>
                <div
                  className="mb-4"
                  onClick={() => {
                    navigate("/back-end-development-course");
                    setActivetab("back_end");
                    setToggle(!toggle);
                  }}
                >
                  <label
                    className={`open_sans fs_18 cursor_pointer ${
                      activetab === "back_end"
                        ? "text_underline_decoration color_FF2852 fw_500"
                        : "color_black"
                    }`}
                  >
                    Back-End
                  </label>
                </div>
                <div
                  className="mb-4"
                  onClick={() => {
                    navigate("/mobile-development-course");
                    setActivetab("mobile");
                    setToggle(!toggle);
                  }}
                >
                  <label
                    className={`open_sans ms-lg-5 ms-2 fs_17 ${
                      activetab === "mobile"
                        ? "text_underline_decoration color_FF2852 fw_600"
                        : "color_black fw_500"
                    }`}
                  >
                    Mobile Application
                  </label>
                </div>
                <div
                  onClick={() => {
                    window.open("https://chidiyaghar.in", "_blank");
                  }}
                  className={`mb-4 open_sans fs_18 cursor_pointer ${
                    activetab === "graphic"
                      ? "text_underline_decoration color_FF2852 fw_500"
                      : "color_black fw_500"
                  }`}
                >
                  Graphic Design
                </div>
                <div
                   onClick={() => {
                    navigate("/manual-testing");
                    setActivetab("manual_testing");
                    setIsActiveTab(!isActiveTab);
                  }}
                  className={`mb-4 open_sans fs_18 cursor_pointer ${
                    activetab === "manual_testing"
                      ? "text_underline_decoration color_FF2852 fw_500"
                      : "color_black fw_500"
                  }`}
                >
                  Manual Testing
                  {/* <div className="position-fixed">
                    {isDropdownOpen && (
                      // <DropdownMenu onSelect={handleOptionSelect} />
                      <div
                        className=""
                        style={{
                          backgroundColor: "#ff2852",
                          border: "1px solid",
                          borderColor: "red",
                          color: "white",
                          borderRadius: "10px",
                          margin: "10px",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <li
                            id="list-1"
                            onMouseEnter={() => {
                              document.getElementById("list-1").style.color =
                                "black";
                            }}
                            onMouseLeave={() => {
                              document.getElementById("list-1").style.color =
                                "white";
                            }}
                            style={{ padding: "5px 14px" }}
                            onClick={handleLocalManualTestingClick}
                          >
                            View Details
                          </li>
                          <li
                            id="list"
                            onMouseEnter={() => {
                              document.getElementById("list").style.color =
                                "black";
                            }}
                            onMouseLeave={() => {
                              document.getElementById("list").style.color =
                                "white";
                            }}
                            style={{ padding: "5px 14px" }}
                            onClick={() => {
                              handleEnrollModalShow();
                              setActivetab("manual_testing");
                              setIsDropdownOpen(false);
                              setSelectedOption(true);
                            }}
                          >
                            Enroll now
                          </li>
                        </ul>
                      </div>
                    )}
                  </div> */}
                </div>

                {activetab !== "work_shop" && (
                  <div
                    className="mb-4"
                    onClick={() => {
                      scrollToSection("ContactUs");
                      setActivetab("contact_us");
                      closeMobileMenu();
                      setToggle(!toggle);
                    }}
                  >
                    <label
                      className={`open_sans fs_18 cursor_pointer ${
                        activetab === "contact_us"
                          ? "text_underline_decoration color_FF2852 fw_600"
                          : "color_black"
                      }`}
                    >
                      Contact Us
                    </label>
                  </div>
                )}

                <div
                  className={`${
                    activetab === "work_shop"
                      ? "color_bg_0F42DB"
                      : "color_bg_white"
                  } boredr_1px_0F42DB me-4 px-4 py-2 border_radus_5px cursor_pointer mb-4`}
                  onClick={() => navigate("/work-shop")}
                >
                  <button
                    className={`border-0 text-center fw_500 fs_16 ${
                      activetab === "work_shop" ? "text-white" : "color_0F42DB"
                    }`}
                    style={{ backgroundColor: "transparent" }}
                  >
                    Free Workshop
                  </button>
                </div>
                <div className="mb-5">
                  {/* Scroll to the top and close the toggle when "Enroll Now" is clicked */}
                  <button
                    className="enrollbtn px-5 py-2"
                    onClick={() => {
                      handleEnrollModalShow();
                      scrollToSection("Curriculum"); // Scroll to the top of the section
                      closeMobileMenu();
                    }}
                  >
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <SubscriptionModal
        subscriptionModalShow={subscriptionModalShow}
        handleSubscriptionModalClose={handleSubscriptionModalClose}
      />
    </>
  );
};

export default MainNavbar;
