import React from 'react'
import PrivacyPolicyImg from "../../assets/privacy_policy_img.svg"
import ArrowBackIcon from "../../assets/Arrow_back.svg"
import { useNavigate } from 'react-router';
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex justify-content-center"
      style={{ backgroundColor: "white" }}
    >
      <div className={`privacy_policy mt-lg-3 mb-3 mb-md-0 mx-3 mx-xl-0`}>
        {/* <div className="d-flex align-items-center mt-3">
          <div className='cursor_pointer' onClick={()=> navigate(-1)}>
            <img src={ArrowBackIcon} width={30} alt="Back_icon" />
          </div>
          <div className="fs_16 fw_500 secondary_color ms-2 mt-1">Back</div>
        </div> */}
        <div className="row g-0 px-3 mx-lg-0">
          <div className="col-md-6 col-12 mt-3 mt-md-0 d-flex align-items-center">
            <div>
              <h5 className="fw_sm_500 fw_700 fs_sm_18 fs_28">
                Privacy Policy
              </h5>
              <div className="fs_14 col-md-11 mt-3 secondary_color">
                At Almnd, we are committed to protecting the privacy of
                our users. We understand that you may have concerns about how
                your personal information is collected, used, and shared, and we
                want to assure you that we take your privacy seriously.
              </div>
            </div>
          </div>
          <div className="col-6 d-none d-md-block">
            <span className='d-none d-lg-block'>
              <img
                src={PrivacyPolicyImg}
                width={430}
                height={350}
                alt="Privacy_Policy_Img"
              />
            </span>
            <span className='d-block d-lg-none'>
              <img
                src={PrivacyPolicyImg}
                width={370}
                height={350}
                alt="Privacy_Policy_Img"
              />
            </span>
          </div>
        </div>
        <div className="ps-md-5">
          <h5 className="fw_500 fs_sm_18 fs_20 mt-5">Information We Collect</h5>
          <ul className="col-lg-9 secondary_color mt-0 mt-sm-4">
            <li className="mt-0 mt-sm-3 fs_14 ">
              <span></span>
              We may collect personal information from you when you visit our
              website or use our services, such as:
              <br />
              <span className="">
                Name, email address, phone number, and other contact information
                Property search preferences, including location, price range,
                and property type Browsing and search history on our website
                Demographic information, such as age, gender, and occupation
                Information provided in feedback, surveys, or other forms of
                communication How We Use Your Information{" "}
              </span>
            </li>
            <li className="mt-3 fs_14">
              We use your personal information to provide and improve our
              services, such as:
              <br />
              Matching you with relevant property listings and real estate
              agents Personalizing your user experience based on your
              preferences and search history Sending you newsletters,
              promotional emails, and other marketing materials Responding to
              your inquiries, feedback, and support requests Conducting research
              and analysis to improve our services and user experience We may
              also share your personal information with third-party service
              providers, such as real estate agencies, mortgage lenders, and
              marketing partners, who assist us in providing our services. We
              will only share your information with these providers to the
              extent necessary to fulfill our obligations to you and as allowed
              by applicable law.
            </li>
          </ul>

          <h5 className="mt-md-5 pt-3 fs_sm_18 fs_20">Your Choices and Rights</h5>

          <ul className="fs_14 secondary_color mt-sm-3  col-lg-9">
            <li className="mt-sm-3 fs_14">
              You have the right to access, update, and delete your personal
              information by contacting us at{" "}
              {/* <Link
                href="mailto:Zameen_Square@firstfloor.agency"
                target="_self"
                className="fw_600 px-1"
              > */}
                {/* Zameen_Square@firstfloor.agency */}
                join@almnd.com
              {/* </Link> */}
              .
              <br />
              You may also opt out of receiving marketing communications from us
              by following the unsubscribe instructions included in our emails.
            </li>
            <li className="mt-3 fs_14">
              Please note that we may retain certain information as required by
              law or for legitimate business purposes, such as fraud prevention
              or dispute resolution.
            </li>
          </ul>

          <h5 className="mt-md-5 pt-3 col-md-8 fs_sm_18 fs_20">Security Measures</h5>
          <ul className="fs_14 secondary_color mt-sm-3 col-lg-9">
            <li className="mt-sm-4">
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. However, no data
              transmission or storage system can be guaranteed to be 100%
              secure. If you have reason to believe that your interaction with
              us is no longer secure, please contact us immediately.
            </li>
          </ul>

          <h5 className=" mt-md-5 pt-3 col-lg-9 fs_sm_18 fs_20">
            Changes to This Privacy Policy
          </h5>
          <div className="mt-sm-4 secondary_color col-sm-9 fs_14 mb-5 pb-3">
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any material changes by posting a notice
            on our website or by sending you an email. Your continued use of our
            services following any changes to this Policy constitutes your
            acceptance of such changes.
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy