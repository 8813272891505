import React, {useEffect, useState} from 'react'
import FormModal from '../formmodal/FormModal';
import { useLocation } from 'react-router';

const DwnldSyllabus = () => {
    const router = useLocation();
    const [activetab, setActivetab] = useState("");
    const [formModalShow, setFormModalShow] = useState(false);
    const handleFormModalClose = () => {
        setFormModalShow(false);
    };
    const handleFormModalShow = () => setFormModalShow(true);
    const activeTabFn = () => {
        if (router.pathname.startsWith("/full-stack-development-course")) {
          return "full_stack";
        } else if (router.pathname.startsWith("/front-end-development-course")) {
          return "front_end";
        } else if (router.pathname.startsWith("/back-end-development-course")) {
          return "back_end";
        } else if (router.pathname.startsWith("/mobile-development-course")) {
          return "mobile";
        } else if (router.pathname.startsWith("/")) {
          return "full_stack";
        }
      };
    
      useEffect(() => {
        const newActiveTab = activeTabFn();
        setActivetab(newActiveTab);
      }, [router.pathname]);
      console.log(activetab, "activetab");
    return (
        <>
            <FormModal
                formModalShow={formModalShow}
                handleFormModalClose={handleFormModalClose}
                title={"Enter Details to Download"}
                description={"Unlock Your Potential: Download Now!"}
                selectedCourse = {activetab}
            />
            <div className=' d-flex justify-content-center mt-5'>
                <div className='col-xxl-10 col-11 '>
                    <div className=' d-flex justify-content-center mt-lg-5 mt-0 ms-lg-5 ms-0'>
                        <div className=' d-lg-flex justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <div className="mt-5 ps-lg-5 px-2 px-lg-0">
                                    <div className='DM_Serif_Text titlepayment text-center text-lg-start'>Journey in Almnd</div>
                                    <div className='open_sans d-flex justify-content-center mt-3 color_969696 fs_sm_15 fs_16'>Our immersive curriculum, guided by industry experts, empowers you to bridge the gap between theory and practice. Through project-based learning and real-world challenges, you'll gain the skills and confidence needed to excel in your tech career.</div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-12'>
                                <div className=' d-flex justify-content-center justify-content-lg-end mt-lg-5 py-lg-5 py-4'>
                                    <button className='mt-lg-5 px-5 p-3 syllabusbtn' onClick={() => handleFormModalShow()}>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DwnldSyllabus