import React, { useEffect, useState } from "react";
import "../style.css";
import "./curriculum.css";
import commenticon from "../../assets/comment-dots 1.png";
import headerimg from "../../assets/Group 40.png";
import arrow from "../../assets/Line 2.png";
import FormModal from "../formmodal/FormModal";
import EnrollNowModal from "../formmodal/EnrollNowModal";
import "../Curriculum/curriculum.css";
import { useLocation, useNavigate } from "react-router";
import FullStackImg from "../../assets/full-stack-dev-img(new).png";
import FrontEndImg from "../../assets/Front-end-dev-img.png";
import BackEndImg from "../../assets/back-end-dev-img.png";
import MobileAppImg from "../../assets/mobile-dev-curriculum-img.png";
const Curriculum = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const [formModalShow, setFormModalShow] = useState(false);
  const handleFormModalClose = () => {
    setFormModalShow(false);
  };
  const handleFormModalShow = () => setFormModalShow(true);

  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const [activeTab, setActiveTab] = useState("home")
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  const coursesDetails = [
    {
      courseImg: FullStackImg,
      courseTitle: (
        <span>
          Full-Stack Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Offline",
      weeks: 4,
      courseType: "full_stack"
    },
    {
      courseImg: FrontEndImg,
      courseTitle: (
        <span>
          Front-End Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Offline",
      weeks: 4,
      courseType: "front_end"
    },
    {
      courseImg: BackEndImg,
      courseTitle: (
        <span>
          Back-End Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Offline",
      weeks: 4,
      courseType: "back_end"
    },
    {
      courseImg: MobileAppImg,
      courseTitle: (
        <span>
          Mobile App Development <br />
          Course
        </span>
      ),
      online: "Online",
      ofline: "Ofline",
      weeks: 4,
      courseType: "mobile"
    },
  ];
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else {
      return "home";
    }
  };
  
  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActiveTab(newActiveTab);
  }, [router.pathname]);
  const viewCourseFn = (courseType)=>{
    if(courseType === "full_stack"){
      navigate('/full-stack-development-course')
    } else if(courseType === "front_end"){
      navigate('/front-end-development-course')
    } else if(courseType === "back_end"){
      navigate('/back-end-development-course')
    } else if(courseType === "mobile"){
      navigate('/mobile-development-course')
    }
  }
  return (
    <>
      <FormModal
        formModalShow={formModalShow}
        handleFormModalClose={handleFormModalClose}
        title={"Curriculum"}
        description={"Unlock Your Potential: Download Now!"}
      />
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Enroll now"}
        description={"Unlock Your Potential: Enroll Now!"}
      />
      <div className="mt-5">
        <div className="col-xl-11 col-12 pb-5 pb-sm-0">
          <div className="d-lg-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-12">
              <div className="mt-5 px-lg-5 mx-5 ms-lg-5 me-lg-0 lefttext">
                <div className="d-flex text-align-center align-items-center gap-2 ms-lg-5 ps-xl-5 ">
                  <div>
                    <img src={commenticon} />
                  </div>
                  <div className="open_sans commenticon fs_15 fs_sm_12 ">
                  Launching Your Career for the Next Sphere
                  </div>
                </div>
                <h4
                  className=" fs_lg_28 fs_32 letter_spacing fw_600 DM_Serif_Text mt-3 ms-lg-5 ps-xl-5 line_height_4rem"
                  style={{ maxWidth: "500px" }}
                >
                  Redefining the Next Generation of Skill Development and Career Launch
                </h4>
                <div className="embark open_sans mt-4 ms-lg-5 ps-xl-5">
                Experience the Education revolution with Flexible Fees, Dynamic Curriculum and transformative Experiences for Limitless Growth.
                </div>
                <div className="d-flex gap-5 mt-4 ms-lg-5 ps-xl-5">
                  <button
                    className="enrollblubtn px-4 py-2 "
                    onClick={() => handleEnrollModalShow()}
                  >
                    Enroll Now
                  </button>
                  {/* <button
                    className="curriculumbtn"
                    onClick={() => handleFormModalShow()}
                  >
                    <span>Curriculum</span>
                    <span className="ps-3">
                      <img src={arrow} />
                    </span>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-5 mt-lg-0 pt-5 pt-lg-0">
                  <div className="row g-0 justify-content-center justify-content-lg-start gap-5">
                    {coursesDetails?.map((item, index) => {
                      if (index < 2) {
                        return (
                          <div className={`col-sm-5 col-8 border_1px_F5F5F5 border_radus_18px ms-xxl-4 p-xxl-4 p-3 mx-xxl-4`}>
                            <div className={``}>
                              <img className="w-100" src={item?.courseImg} />
                              <h1 className="DM_Serif_Text mt-2 ms-1 fs_lg_20 fs_22">
                                {item?.courseTitle}
                              </h1>
                              <div className="row gap-2 ms-1">
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_specificition">
                                <label className="color_0F42DB">{item?.online}</label>
                              </div>
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_specificition">
                                <label className="color_0F42DB">{item?.ofline}</label>
                              </div>
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_duration">
                                <label className="color_0F42DB">{item?.weeks} Months</label>
                              </div>
                            </div>
                            </div>
                            <button className="border-0 p-2 border_radus_7px text-white fs_lg_16 fs_18 fw_500 course_enroll_btn mt-3 w-100" onClick={()=> viewCourseFn(item?.courseType)}>View Details</button>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="row g-0 justify-content-center justify-content-lg-start justify-content-xxl-end gap-5 ms-xxl-5 mt-5 pt-3 mx-xxl-4">
                  {coursesDetails?.map((item, index) => {
                    if (index > 1) {
                      return (
                        <div className={`col-sm-5 col-8 border_1px_F5F5F5 border_radus_18px p-xxl-4 p-3 ${index === 2 && "me-xxl-4"}`}>
                          <div className={``}>
                            <img className="w-100" src={item?.courseImg} />
                            <h1 className="DM_Serif_Text mt-2 ms-1 fs_lg_20 fs_22">
                              {item?.courseTitle}
                            </h1>
                            <div className="row gap-2 g-0 ms-1">
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_specificition">
                                <label className="color_0F42DB">{item?.online}</label>
                              </div>
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_specificition">
                                <label className="color_0F42DB">{item?.ofline}</label>
                              </div>
                              <div className="col-4 fs_lg_12 fs_14 fw_600 border_color_0F42DB open_sans p-1 text-center border_radus_7px course_duration">
                                <label className="color_0F42DB">{item?.weeks} Months</label>
                              </div>
                            </div>
                            <button className="border-0 p-2 border_radus_7px text-white fs_lg_16 fs_18 fw_500 course_enroll_btn mt-3 w-100" onClick={()=> viewCourseFn(item?.courseType)}>View Details</button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;
