import React from 'react';
import { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessModal from './SuccessModal';
import axios from 'axios';
import Select from 'react-select';
import SuccessToast from '../SuccessToast';
import ErrorToast from '../ErrorToast';
import closeIcon from '../../assets/icons/cancel-icon.svg'

const PostpaidModal = ({
    PostpaidModalShow,
    handlePostpaidModalClose,
    title = "",
    description,
    subsection = false,
    mode,
    selectedCourse = "",
    title_color = "open_sans successtitle" }) => {

    const options = [
        { label: "Prepaid", value: "pre_paid" },
        { label: "Postpaid", value: "post_paid" }
    ]

    // const phoneRegExp =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        const phoneRegExp = /^[6,7,8,9][0-9]{0,9}$/;
    const [selectedOption, setSelectedOption] = useState("");

    const [error, setError] = useState("");
    const [otpToken, setOtpToken] = useState('');
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isGetOtpClicked, setIsGetOtpClicked] = useState(false);

    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const [timerSeconds, setTimerSeconds] = useState(30);

    // useEffect(() => {
    //     let countdownInterval;
    //     if (isGetOtpClicked && timerSeconds > 0) {
    //         countdownInterval = setInterval(() => {
    //             setTimerSeconds((prevSeconds) => prevSeconds - 1);
    //         }, 1000);

    //     } else if (timerSeconds === 0 && isGetOtpClicked) {
    //         clearInterval(countdownInterval);
    //         setIsResendEnabled(true);
    //     }
    //     return () => clearInterval(countdownInterval);
    // }, [isGetOtpClicked, timerSeconds]);

    const EnrollApi = (values) => {
        axios
            .post("https://api.almnd.in/api/enroll-now-create", {
                name: values?.name,
                email_address: values?.email,
                phone_number: values?.phone,
                course_type: "",
                selected_course: selectedCourse,
                mode: mode
            })
            .then((result) => {
                console.log(result.data);
                SuccessToast(result.data.message);
                // handleSuccessMessageShow();
                setError("");
            })
            .catch((error) => {
                console.log(error.response.data.error, "preerror");
                ErrorToast(error.response.data.message)
                setError(error.response.data.error);
            });
    };

    // const getOtpApi = (values) => {
    //     setIsGetOtpClicked(true);
    //     setIsResendEnabled(false);
    //     setTimerSeconds(30);
    //     axios
    //         .post("https://api.almnd.in/api/auth/send-otp", {
    //             phone_number: values?.phone,
    //             country_code: values?.country_code,
    //         })
    //         .then((result) => {
    //             console.log(result.data, "otp");
    //             SuccessToast(result.data?.message);
    //             // setSuccessMessageShow(false);
    //             // handleSuccessMessageShow();
    //             setOtpToken(result.data?.data?.otp_token);
    //             setIsOtpVerified(false);
    //             setError("");
    //         })
    //         .catch((error) => {
    //             console.log(error.response.data.error);
    //             ErrorToast(error.response.data.message);
    //             setError(error.response.data.error);
    //         });
    // };
    // const handleVerifyOtp = () => {
    //     verifyOtpApi(formik.values);
    // };

    // const verifyOtpApi = (values) => {
    //     axios
    //         .post("https://api.almnd.in/api/auth/verify-otp", {
    //             otp: values?.otp,
    //             otp_token: otpToken,
    //         })
    //         .then((result) => {
    //             console.log(result.data, "otp");
    //             SuccessToast(result.data?.message);
    //             // setSuccessMessageShow(false);
    //             setIsOtpVerified(true);
    //             setError("");
    //         })
    //         .catch((error) => {
    //             console.log(error.response.data.error);
    //             ErrorToast(error.response.data.message);
    //             setError(error.response.data.error);
    //         });
    // };

    // const resendOtpApi = (values) => {
    //     axios
    //         .post("https://api.almnd.in/api/auth/resend-otp", {
    //             otp_token: otpToken, // Use the existing OTP token
    //         })
    //         .then((result) => {
    //             console.log(result.data, "otp");
    //             SuccessToast(result.data?.message);
    //             // setSuccessMessageShow(false);
    //             setIsOtpVerified(false);
    //             setError("");

    //             // Capture the new OTP token from the response
    //             const newOtpToken = result.data?.data?.otp_token;
    //             setOtpToken(newOtpToken); // Update the OTP token in state
    //         })
    //         .catch((error) => {
    //             console.log(error.response.data.error);
    //             ErrorToast(error.response.data.message);
    //             setError(error.response.data.error);
    //         });
    // };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            email: "",
            phone: "",
            // otp: "",
            // country_code: "+91",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("!Required"),
            email: Yup.string().required("!Required"),
            phone: Yup.string()
                .required("!Required")
                .matches(phoneRegExp, "Phone number is not valid")
                .min(10, "Minimum requires 10 numbers")
                .max(10, "Maximum requires 10 numbers only"),
            // otp: Yup.string().required("!Required"),
            // country_code: Yup.string(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log(values, "formikvalues");
            EnrollApi(values)
            resetForm();
            handlePostpaidModalClose();
            handleModalClose();
            // handleSuccessMessageShow();
            setError(null);
        },
    });
    const handleModalClose = () => {
        formik.resetForm();
        handlePostpaidModalClose()
        // setIsGetOtpClicked(false);
        // setTimerSeconds(30);
        // setIsResendEnabled(false);
        // setIsOtpVerified(false);
    };

    // const startTimer = () => {
    //     setIsGetOtpClicked(true);
    //     setTimerSeconds(30);
    //     setIsResendEnabled(false);

    //     const countdownInterval = setInterval(() => {
    //         setTimerSeconds((prevSeconds) => prevSeconds - 1);
    //     }, 1000);
    //     setTimeout(() => {
    //         clearInterval(countdownInterval);
    //         setIsResendEnabled(true);
    //     }, 30000);
    // };

    return (
        <>
            {/* <SuccessModal
            successMessageShow={successMessageShow}
            handleSuccessMessageClose={handleSuccessMessageClose}
            title={`${title === "Curriculum" ? "Syllabus downloaded successfully" : "Joined to workshop successfully"}`}
        /> */}
            <Modal
                centered
                show={PostpaidModalShow}
                onHide={handleModalClose}
                dialogClassName="appproved_form_container"
                contentClassName="border_radius_10px"
                backdropClassName="create_form_modal_backdrop"
                backdrop={"false"}
                keyboard={false}
            >
                <Modal.Body className='px-5 mx-3 py-5'>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className={`open_sans titleheading fs_22 ${title_color}`}>{title}</div>
                        <div> <img src={closeIcon} alt="" onClick={() => handleModalClose()} className="Enroll_Modal_Closeicon" /> </div>
                    </div>
                    <div className="Enroll_Modal_Title ">{description}</div>
                    {/* {subsection ? (
                    <div className="d-flex justify-content-center mt-3">
                        <span className="text-center fw_500 fs_16 spanish_gray_color">
                            {description}
                        </span>
                    </div>
                ) : null} */}
                    <div className='mt-5'>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <lable className={`labels open_sans ${formik.touched.name && formik.errors.name
                                    ? " errorcolor"
                                    : ""
                                    }`}>Name</lable>
                                <input
                                    className={`w-100 inputfields p-3 ps-2 ${formik.touched.name && formik.errors.name
                                        ? "inputfieldserror"
                                        : ""
                                        }`}
                                    type="text"
                                    placeholder="Enter your name"
                                    id="name"
                                    name="name"
                                    onChange={formik.handleChange}
                                    // onChange={(e) => {
                                    //     setName(e.target.value);
                                    // }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                // value={name}
                                // onChange={handleName}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="errorcolor open_sans ps-2">{formik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className='mt-5'>
                                <lable className={`labels open_sans ${formik.touched.email && formik.errors.email
                                    ? " errorcolor"
                                    : ""
                                    }`}>Email Address</lable>
                                <input
                                    className={`w-100 inputfields p-3 ps-2 ${formik.touched.email && formik.errors.email
                                        ? "inputfieldserror"
                                        : ""
                                        }`}
                                    type="email"
                                    placeholder="Enter your email address"
                                    id="email"
                                    email="email"
                                    onChange={formik.handleChange}
                                    // onChange={(e) => {
                                    //     setEmail(e.target.value);
                                    // }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                // value={email}
                                // onChange={handleEmail}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="errorcolor open_sans ps-2">{formik.errors.email}</div>
                                ) : null}
                            </div>
                            <div className='mt-5'>
                                <lable className={`labels open_sans ${formik.touched.phone && formik.errors.phone
                                    ? " errorcolor"
                                    : ""
                                    }`}>Phone Number</lable>
                                <input
                                    className={`w-100 inputfields p-3 ps-2 ${formik.touched.phone && formik.errors.phone
                                        ? "inputfieldserror"
                                        : ""
                                        }`}
                                    type="text"
                                    placeholder="Enter your phone number"
                                    maxlength="10"
                                    pattern="\d{10}"
                                    id="phone"
                                    name="phone"
                                    onChange={formik.handleChange}
                                    // onChange={(e) => {
                                    //     setPhone(e.target.value);
                                    // }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="errorcolor open_sans ps-2">{formik.errors.phone}</div>
                                ) : null}
                            </div>
                            {/* <div className='mt-5'>
                                <lable className={`labels open_sans`}>Course Type</lable>
                                <Select
                                    isDisabled={true}
                                    defaultValue={"post_paid"}
                                    placeholder="Post paid"
                                    className={`w-100 selectfonts p-3 ps-2`}
                                    options={options}
                                    onChange={(selectedOption) => {
                                        setSelectedOption(selectedOption.value); // Update the selected option value in state
                                        formik.setFieldValue('payment', selectedOption.value); // Update the formik field value
                                    }}
                                    id="payment"
                                    name="payment"
                                    onBlur={formik.handleBlur}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                />
                            </div> */}
                            <div>{error}</div>
                            <div className='mt-5'>
                                <button type='submit' className=' w-100 px-5 p-3 submitbtn'
                                // onClick={handleApi}
                                //  onClick={() => handleSuccessMessageShow()}
                                >{"Join now"}</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PostpaidModal