import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Curriculum from "../../components/Curriculum/curriculum";
import Impressivestats from "../../components/ImpressiveStats/Impressivestats";
import Footer from "../../components/Footer/Footer";
import Contactus from "../../components/ContactUs/contactus";
import Faqs from "../../components/Faqs/faqs";
import PaymentCards from "../../components/paymentCards/PaymentCards";
import WhatMore from "../../components/whatmore/WhatMore";
import DwnldSyllabus from "../../components/dwnldsyllabus/DwnldSyllabus";
import TechnologiesTaught from "../../components/technologiestaught/TechnologiesTaught";
import OurAlumni from "../../components/ouralumni/OurAlumni";
import Partners from "../../components/partners/Partners";
import EmbarkLearning from "../../components/embarkLearning/EmbarkLearning";
import FreeWorkshop from "../../components/freeWorkshop/FreeWorkshop";
import WeekTimeLine from "../../components/weektimeline/WeekTimeLine";
import RolesCanFit from "../../components/roleCanFit/RolesCanFit";
import { useNavigate } from "react-router";
import GoToTop from "../../components/helper/go-to-top";
import SubscriptionModal from "../../components/formmodal/subscriptionModal";

const Almnd = () => {
  const [isHomeClicked, setIsHomeClicked] = useState(false);
  const sectionRefs = {
    Curriculum: useRef(null),
    ContactUs: useRef(null),
    DwnldSyllabus: useRef(null),
    PaymentCards: useRef(null),
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isHomeClicked]);
  const impressivestats = {
    no_of_stud_label: "No. of Students",
    no_of_stud_value: 252,
    hiring_partners_label: "Hiring Partners",
    hiring_partners_value: 112,
    avg_sal_label: "Average Salary",
    avg_sal_value: "10L",
  };
 
  return (
    <div className="">
      <Navbar
        isActiveTab={isHomeClicked}
        setIsActiveTab={setIsHomeClicked}
        scrollToSection={scrollToSection}
      />
      <div>
          <div className="mt-5 pt-1">
            <Curriculum />
          </div>
          <Impressivestats impressivestats={impressivestats} />
          <FreeWorkshop />
          <div className="pt-sm-5 mt-5">
            <Partners />
          </div>
          <div className="mt-sm-5 pt-sm-5" ref={sectionRefs.ContactUs}>
            <Contactus />
          </div>
        </div>
       <Footer scrollToSection={scrollToSection} />
          <GoToTop />
    </div>
  );
};

export default Almnd;
