import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Impressivestats from "../../components/ImpressiveStats/Impressivestats";
import Footer from "../../components/Footer/Footer";
import Contactus from "../../components/ContactUs/contactus";
import Faqs from "../../components/Faqs/faqs";
import PaymentCards from "../../components/paymentCards/PaymentCards";
import WhatMore from "../../components/whatmore/WhatMore";
import DwnldSyllabus from "../../components/dwnldsyllabus/DwnldSyllabus";
import TechnologiesTaught from "../../components/technologiestaught/TechnologiesTaught";
import Partners from "../../components/partners/Partners";
import EmbarkLearning from "../../components/embarkLearning/EmbarkLearning";
import FreeWorkshop from "../../components/freeWorkshop/FreeWorkshop";
import WeekTimeLine from "../../components/weektimeline/WeekTimeLine";
import RolesCanFit from "../../components/roleCanFit/RolesCanFit";
import FullStackDevCurriculum from "../../components/Curriculum/full-stack-curriculum";
import GoToTop from "../../components/helper/go-to-top";
const FullStackDevCourse = () => {
  const [isFullStackClicked, setIsFullStackClicked] = useState(false);
  const sectionRefs = {
    ContactUs: useRef(null),
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isFullStackClicked]);
  const impressivestats = {
    no_of_stud_label: "No. of Students",
    no_of_stud_value: 252,
    hiring_partners_label: "Hiring Partners",
    hiring_partners_value: 112,
    avg_sal_label: "Average Salary",
    avg_sal_value: "10L",
  };
  return (
    <div>
      <Navbar
        isActiveTab={isFullStackClicked}
        setIsActiveTab={setIsFullStackClicked}
        scrollToSection={scrollToSection}
      />
      <div className="mt-5 pt-sm-5">
        <FullStackDevCurriculum />
      </div>
      <div className="mt-5 pt-5">
        <Impressivestats impressivestats={impressivestats} />
      </div>
      <EmbarkLearning />
      <FreeWorkshop />
      <TechnologiesTaught />
      <div className="pt-lg-5 pt-md-4 pb-md-5">
        <Partners />
      </div>
      <div className="pb-md-5">
        <DwnldSyllabus />
      </div>
      <WeekTimeLine />
      <WhatMore />
      <RolesCanFit />
      <div>
        <PaymentCards />
      </div>
      <div className="pt-5">
        <Faqs scrollToSection={scrollToSection} />
      </div>
      <div className="mt-sm-5 pt-sm-5" ref={sectionRefs.ContactUs}>
        <Contactus />
      </div>

      <Footer scrollToSection={scrollToSection} />
      <GoToTop />
    </div>
  );
};

export default FullStackDevCourse;
