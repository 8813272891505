import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Index from "./routes";
import { ToastContainer } from "react-toastify";
// import { Provider } from "react-redux";
// import store from "./redux/store";

function App() {
  return (
    <div>
      <Index />
      <ToastContainer />
    </div>
    // <Provider store={store}>

    // </Provider>
  );
}

export default App;
