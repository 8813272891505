import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import "../style.css";
import axios from "axios";
import ErrorToast from "../ErrorToast";
import SuccessToast from "../SuccessToast";
import closeIcon from "../../assets/icons/cancel-icon.svg";

const SubscriptionModal = ({
  subscriptionModalShow,
  handleSubscriptionModalClose,
}) => {
  const [error, setError] = useState("");
  const handleApi = (values) => {
    axios
      .post("https://api.almnd.in/api/subscribe-news-letter", {
        mobile: values?.phone,
      })
      .then((result) => {
        console.log(result.data, "hgghxgfxgfx");
        SuccessToast(result.data.message);
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required("!Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, "Must be at least 10 digits")
        .max(15, "Must not exceed 15 digits"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      handleApi(values);
      handleSubscriptionModalClose();
      resetForm();
      setError(null);
    },
  });

  return (
    <>
      <Modal
        centered
        show={subscriptionModalShow}
        onHide={handleSubscriptionModalClose}
        dialogClassName="appproved_successfully_container mx-auto px-3"
        contentClassName="border_radius_10px"
        backdropClassName="create_password_modal_backdrop"
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Body className="px-sm-5  mx-sm-3 py-5">
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className={`titleheading DM_Serif_Text fs_20`}>
              Get Latest Discount
            </div>
            <div>
              {" "}
              <img
                src={closeIcon}
                alt=""
                onClick={() => handleSubscriptionModalClose()}
                className="Enroll_Modal_Closeicon"
              />{" "}
            </div>
          </div>
          <div className="mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-4">
                <lable
                  className={`fs_13 fw_600 open_sans ${
                    formik.touched.phone && formik.errors.phone
                      ? " errorcolor"
                      : ""
                  }`}
                >
                  Phone Number
                </lable>
                <input
                  className={`w-100 inputfields ps-2 ${
                    formik.touched.phone && formik.errors.phone
                      ? "inputfieldserror"
                      : ""
                  }`}
                  type="tel"
                  placeholder="Enter your phone number"
                  id="phone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>

              <div className="mt-5">
                <button
                  type="submit"
                  className={`w-100 px-5 p-2 ${
                    !formik.dirty || !formik.isValid
                      ? "inactivebtn"
                      : "submitbtn"
                  }  `}
                  disabled={!formik?.dirty || !formik?.isValid}
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
