import React, { useState } from 'react';
import rolesBackImg from '../../assets/Group 41.png';
import startImg from '../../assets/Vector (2).png';
import '../style.css'
import EnrollNowModal from '../formmodal/EnrollNowModal';
const RolesCanFit = () => {
  const [EnrollModalShow, setEnrollModalShow] = useState(false);
  const handleEnrollModalClose = () => {
    setEnrollModalShow(false);
  };
  const handleEnrollModalShow = () => setEnrollModalShow(true);
  return (
    <>
      <EnrollNowModal
        EnrollModalShow={EnrollModalShow}
        handleEnrollModalClose={handleEnrollModalClose}
        title={"Apply Now"}
        description={"Unlock Your Potential: Apply Now!"}
      />
      <div className='d-flex justify-content-center mt-md-5 pt-5'>
      <div className='rolls_fit_background rolls_fit_backImg pb-md-5 mt-md-5 mb-md-5' style={{ maxWidth: "140rem" }}>
          <div className='DM_Serif_Text fs_xs_22 fs_sm_24 fs_lg_30 fs_36 fw_400 text-white text-center pt-md-5 pt-3'>Roles You Can Fit in</div>
        <div className='d-flex justify-content-center open_sans rolls_fit_title text-center pt-md-2  pt-3 pb-md-5 pb-2'>
          <div className='col-xxl-8 col-12 color_E1E1E1 fs_14'>
          Carving Your Distinctive Niche: Crafting Expertise in the Dynamic and Ever-Evolving Digital Landscape.
          </div>
        </div>
        <div className='row '>
          <div className='col-md-6 col-12 '>
            <div className='d-flex justify-content-md-end justify-content-center ms-md-5'>
              <div className='d-flex align-items-center gap-3 py-4 px-1  my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} className='ps-2' alt='' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>Full-Stack Developer</span></div>
              </div>
            </div>
            <div className='d-flex justify-content-md-end justify-content-center ms-md-5'>
              <div className='d-flex align-items-center gap-3 py-4 px-1 my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} className='ps-2' alt='' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>Front-End Developer</span></div>
              </div>
            </div>
            <div className='d-flex justify-content-md-end justify-content-center ms-md-5'>
              <div className='d-flex align-items-center gap-3 py-4 px-1 my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} alt='' className='ps-2' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>Back-End Developer</span></div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='d-flex justify-content-md-start justify-content-center'>
              <div className='d-flex align-items-center gap-3 py-4 px-3 my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} alt='' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>Entrepreneur</span></div>
              </div>
            </div>
            <div className='d-flex justify-content-md-start justify-content-center'>
              <div className='d-flex align-items-center gap-3 py-4 px-3 my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} alt='' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>Web Designer</span></div>
              </div>
            </div>
            <div className='d-flex justify-content-md-start justify-content-center'>
              <div className='d-flex align-items-center gap-3 py-4 px-3 my-4 rolls_fit_availbox_full_stack '>
                <div className=''><img src={startImg} alt='' /></div>
                <div className=''><span className='rolls_fit_availbox_title'>React Developer</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center py-md-5 py-2 pb-5 mb-5  '>
          <button className='apply_now_btn px-5 py-3 ' onClick={() => handleEnrollModalShow()}>Apply Now</button>
        </div>
      </div>
      </div>
    </>
  );
};

export default RolesCanFit;
