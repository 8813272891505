import React, { useState } from "react";
import ArrowIcon from "../../assets/Group.png";
import weekimg from "../../assets/week list.png";
import leftarrow from "../../assets/Group 23.png";
import rightarrow from "../../assets/Group 26.png";
import { array } from "yup";
import "./weekTimeline.css";
import useScreenSize from "../helper/UserScreenSize";

const WeekTimeLine = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const width = useScreenSize();
  const weekList = [
    {
      value: 1,
      title: "Week 1",
      session1: "Introduction to HTML Tags",
      session2: "Basics of Programming Concepts",
      session3: "Introduction to Visual Studio Code (VS Code) & Node.js",
      session4: "Installation and Significance of VS Code and Node.js",

    },
    {
      value: 2,
      title: "Week 2",
      session1: "In-depth HTML Concepts",
      session2: "Introduction to CSS",
      session3: "Styling Web Pages with CSS",
    },
    {
      value: 3,
      title: "Week 3",
      session1: "Creating Responsive Web Pages with HTML and CSS",
      session2: "Introduction to Flexbox for Responsive Web Pages",
      session3: "Responsive Design Principles",
    },

    {
      value: 4,
      title: "Week 4",
      session1: "Introduction to Bootstrap Framework",
      session2: "Utilising Icons in Web Development",
      session3: "Introduction to JavaScript Concepts",
      session4: "Exploring Node.js",
    },
    {
      value: 5,
      title: "Week 5",
      session1: "ALMND Game: Applying HTML, CSS, BOOTSTRAP & FLEX ",
    },
    {
      value: 6,
      title: "Week 6",
      session1: "Integrating JavaScript with HTML",
      session2: "Comprehensive Understanding of JavaScript",
    },
    {
      value: 7,
      title: "Week 7",
      session1: "Introduction to React: Getting Started",
    },
    {
      value: 8,
      title: "Week 8",
      session1: "Advanced React Concepts",
      session2: "Creating Layouts and Reusing Components",
      session3: "Form Submission in React",
      session4: "Handling JSON Data",
      session5: "Conditional Rendering",
      session6: "Version Control with Git",
    },
    {
      value: 9,
      title: "Week 9",
      session1: "  React State Management with Redux",
      session2: "  Making API Calls in React",
      session3: " Enhancing Form Submissions",
      session4: " Testing APIs with Postman",
    },
    {
      value: 10,
      title: "Week 10",
      session1: "Redux State Management with API Integration",
      session2: "React and Redux Integration",
      session3: "Learning SQL Fundamentals",
    },
    {
      value: 11,
      title: "Week 11",
      session1: "Integrating ALMND Game with SQL Learning",
    },
    {
      value: 12,
      title: "Week 12",
      session1: "  Fundamentals of Database Design",
      session2: "  Introduction to Express.js Framework",
    },
    {
      value: 13,
      title: "Week 13",
      session1: "Working with Sequelize ORM",
      session2: " Database Management with PostgreSQL",
      session3: " Deepening Express.js Knowledge",
      session4: " Introduction to Web Scraping ",
    },
    {
      value: 14,
      title: "Week 14",
      session1: "Implementing a Project Using Express.js (API-Only Approach)",

    },
    {
      value: 15,
      title: "Week 15",
      session1: " Building a Social Networking Web Application with Express.js & React",

    },
    {
      value: 16,
      title: "Week 16",
      session1: " Developing an E-Commerce Web Application using Express.js & React",
    },
  ];

  return (
    <>
      <div className=" d-flex justify-content-center mt-5 mx-auto">
        <div className="col-lg-10 col-12 mt-5" style={{overflowX: width < 768 && "scroll"}}>
          <div className="row g-0" style={{width: width < 768 && "700px", overflowX: width < 768 && "scroll"}}>
            <div className="col-lg-3 col-12 px-4 time_line border_radus_7px">
              <div className="p-2 fs_30 DM_Serif_Text black_color pb-4 text-nowrap">Time Line</div>
              <div className="custom-container d-lg-block d-none">
                {weekList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="d-flex open_sans fs_18 fw-bold mt-5 pt-4 cursor_pointer text-nowrap"
                        onClick={() => setCurrentWeek(item?.value)}
                      >
                        {item?.value === currentWeek ? (
                          <>
                            <img className="" src={ArrowIcon} height={"20px"} width={"20px"} />
                          </>
                        ) : null}
                        <span className="ps-3">{item?.title}</span>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="horizontal-container d-lg-none d-block">
                <div className="d-flex gap-5">
                  {weekList?.map((item, index) => {
                    return (
                      <>
                        <div
                          className="open_sans fs_15 mt-5 cursor_pointer text-nowrap"
                          onClick={() => setCurrentWeek(item?.value)}
                        >
                          <button className={`${item?.value === currentWeek ? "redbtn px-3 p-2" : "nobtn px-3 p-2"}`}>
                            <span>{item?.title}</span>
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 px-3 mt-5 mt-lg-0">
              <div className=" d-flex justify-content-between ms-lg-5">
                <div className="p-2 fs_xs_24 fs_32 DM_Serif_Text black_color">Week {currentWeek}</div>
                <div className="d-lg-none d-lg-block">
                  <div className="d-flex justify-content-end align-items-center pe-2 mt-4">
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre - 1)}
                      onClick={() => {
                        if (currentWeek > 1) {
                          setCurrentWeek((pre) => pre - 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek < 16) {
                            horizontalContainer.scrollLeft -= 100;
                          }
                        }
                      }}

                      className="scrollbtns" disabled={currentWeek === 1}>
                      <img className=" w-50" src={leftarrow} />
                    </button>
                    <button
                      // onClick={() => setCurrentWeek((pre) => pre + 1)} 
                      onClick={() => {
                        if (currentWeek < 16) {
                          setCurrentWeek((pre) => pre + 1);
                          const horizontalContainer = document.querySelector(".horizontal-container");
                          if (currentWeek >= 1) {
                            horizontalContainer.scrollLeft += 100;
                          }
                        }
                      }}
                      className="scrollbtns" disabled={currentWeek === 16}>
                      <img className=" w-50" src={rightarrow} />
                    </button>
                  </div>
                </div>
              </div>
              {weekList
                ?.filter((item) => item?.value === currentWeek)
                .map((data) => {
                  return (
                    <>
                      <div className=" position-relative ms-lg-5">
                        <div className="mt-3">
                          <img className="d-none d-md-block w-100 weekimg" height="500px" src={weekimg} />
                          <img className="d-block d-sm-none w-100 weekimg" height="350px" src={weekimg} />
                          <img className="d-none d-sm-block d-md-none w-100 weekimg" height="400px" src={weekimg} />
                        </div>

                        {
                          data.value === 8 ?
                            <>
                              <div className=" text-center">
                                {data.session1 && <div className="session_1 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session1}
                                </div>}
                                {data?.session2 && <div className="session_2 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session2}
                                </div>}
                                {data?.session3 && <div className="session_3 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session3}
                                </div>}
                                {data.session4 && <div className="session_4 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session4}
                                </div>}
                                {data.session5 && <div className="session_5 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session5}
                                </div>}
                                {data.session6 && <div className="session_6 fs_sm_10 fs_14 open_sans px-1 fw_600">
                                  {data.session6}
                                </div>}
                              </div>
                            </> :
                            <>
                              <div className=" text-center">
                                {data.session1 && <div className="session_11 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session1}
                                </div>}
                                {data?.session2 && <div className="session_22 fs_sm_10 fs_14 open_sans px-3 fw_600">
                                  {data.session2}
                                </div>}
                                {data?.session3 && <div className="session_33 fs_sm_10 fs_14 open_sans px-3 fw_600 me-3 me-sm-0">
                                  {data.session3}
                                </div>}
                                {data.session4 && <div className="session_44 fs_sm_10 fs_14 open_sans px-3 fw_600 me-1 me-sm-0">
                                  {data.session4}
                                </div>}
                              </div>
                            </>
                        }
                      </div>
                    </>
                  );
                })}
              <div className="d-none d-lg-block">
                <div className="d-flex justify-content-end pe-2 mt-5 gap-2">
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre - 1)}
                    onClick={() => {
                      if (currentWeek > 1) {
                        setCurrentWeek((pre) => pre - 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek <= 10) {
                          customContainer.scrollTop -= 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 1}>
                    <img className=" w-75" src={leftarrow} />
                  </button>
                  <button
                    //  onClick={() => setCurrentWeek((pre) => pre + 1)}
                    onClick={() => {
                      if (currentWeek < 16) {
                        setCurrentWeek((pre) => pre + 1);
                        const customContainer = document.querySelector(".custom-container");
                        if (currentWeek >= 7) {
                          customContainer.scrollTop += 80;
                        }
                      }
                    }}
                    className="scrollbtns" disabled={currentWeek === 16}>
                    <img className=" w-75" src={rightarrow} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeekTimeLine;
