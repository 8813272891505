import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../style.css";
import SuccessModal from "../formmodal/SuccessModal";
import axios from "axios";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";

const ContactUs = () => {
  const numRegex = /^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/;

  const [error, setError] = useState("");

  const handleApi = (values) => {
    axios
      .post("https://api.almnd.in/api/contact-us-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
        message: values?.message,
      })
      .then((result) => {
        console.log(result.data);
        SuccessToast(result.data);
        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.error);
        setError(error.response.data.error);
      });
  };

  const [successMessageShow, setSuccessMessageShow] = useState(false);
  const handleSuccessMessageClose = () => {
    setSuccessMessageShow(false);
  };
  const handleSuccessMessageShow = () => setSuccessMessageShow(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("!Required"),
      email: Yup.string().required("!Required"),
      phone: Yup.string()
        .matches(numRegex, "Invalid value")
        .required("Please fill  the fields to proceed"),
      // message: Yup.string().required("!Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      // saveData();
      handleSuccessMessageShow();
      handleApi(values);
      resetForm();
      setError(null);
    },
  });

  return (
    <>
      <SuccessModal
        successMessageShow={successMessageShow}
        handleSuccessMessageClose={handleSuccessMessageClose}
        title={"Your Response has been submited successfully "}
      />
      <div className=" d-flex justify-content-center pb-5">
        <div className="col-10 pb-sm-5">
          <div className=" d-flex justify-content-md-center ">
            <div className=" d-md-flex justify-content-md-center gap-sm-0 gap-md-5 gap-lg-0">
              <div className="col-md-6 col-12">
                <div className="mt-5 d-flex justify-content-xl-end">
                  <div className="col-md-10 col-12 text-left">
                    <h5 className="DM_Serif_Text color_030406 fs_24 fw_500 me-lg-5 pe-lg-5 text-left">Contact Us</h5>
                    <label className="col-xl-10 col-xxl-8 text-left fw_400 fs_16 color_030406 text-left">If you have any inquiries or need further information,
                    please don't hesitate to get in touch. Our dedicated team is
                    ready to help you on your path to tech excellence.</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mt-5">
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <lable
                        className={`fs_14 fw_600 text-black open_sans fw_600 ${
                          formik.touched.name && formik.errors.name
                            ? " errorcolor"
                            : ""
                        }`}
                      >
                        Name
                      </lable>
                      <input
                        className={`w-100 inputfields p-3 ps-2 ${
                          formik.touched.name && formik.errors.name
                            ? "inputfieldserror"
                            : ""
                        }`}
                        type="text"
                        placeholder="Enter your name"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //     setName(e.target.value);
                        // }}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        // value={name}
                        // onChange={handleName}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="errorcolor open_sans ps-2">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <lable
                        className={`fs_14 fw_600 text-black open_sans ${
                          formik.touched.email && formik.errors.email
                            ? " errorcolor"
                            : ""
                        }`}
                      >
                        Email Address
                      </lable>
                      <input
                        className={`w-100 inputfields p-3 ps-2 ${
                          formik.touched.email && formik.errors.email
                            ? "inputfieldserror"
                            : ""
                        }`}
                        type="email"
                        placeholder="Enter your email address"
                        id="email"
                        email="email"
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //     setEmail(e.target.value);
                        // }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        // value={email}
                        // onChange={handleEmail}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="errorcolor open_sans ps-2">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <lable
                        className={`fs_14 fw_600 text-black open_sans ${
                          formik.touched.phone && formik.errors.phone
                            ? " errorcolor"
                            : ""
                        }`}
                      >
                        Phone Number
                      </lable>
                      <input
                        className={`w-100 inputfields p-3 ps-2 ${
                          formik.touched.phone && formik.errors.phone
                            ? "inputfieldserror"
                            : ""
                        }`}
                        type="text"
                        placeholder="Enter your phone number"
                        id="phone"
                        name="phone"
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //     setPhone(e.target.value);
                        // }}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        // value={phone}
                        // onChange={handlePhone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="errorcolor open_sans ps-2">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <lable className={`fs_14 fw_600 text-black open_sans`}>Message</lable>
                      <textarea
                        className={`w-100 inputfields p-3 ps-2`}
                        style={{ minHeight: "10rem", resize: "none" }}
                        type="text"
                        placeholder="Write your query"
                        id="message"
                        name="message"
                      />
                    </div>
                    <div>{error}</div>
                    <div className="mt-5">
                      <button
                        type="submit"
                        className=" w-100 px-5 p-3 submitbtn"
                        // onClick={handleApi}
                        //  onClick={() => handleSuccessMessageShow()}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
