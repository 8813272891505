import React, { useEffect, useMemo, useState } from "react";
import technoimg from "../../assets/Group 25.png";
import FrontendTechImg from "../../assets/front-end-tochonologies-img.png";
import BackendTechImg from "../../assets/backend-techonologies-img.png";
import MobileTechImg from "../../assets/mobile-tech-img.png";
import { useLocation, useNavigate } from "react-router";

const TechnologiesTaught = () => {
  const navigate = useNavigate();
  const router = useLocation();
  const [activetab, setActivetab] = useState("");
  const activeTabFn = () => {
    if (router.pathname.startsWith("/full-stack-development-course")) {
      return "full_stack";
    } else if (router.pathname.startsWith("/front-end-development-course")) {
      return "front_end";
    } else if (router.pathname.startsWith("/back-end-development-course")) {
      return "back_end";
    } else if (router.pathname.startsWith("/mobile-development-course")) {
      return "mobile";
    } else if (router.pathname.startsWith("/")) {
      return "home";
    }
  };

  useEffect(() => {
    const newActiveTab = activeTabFn();
    setActivetab(newActiveTab);
  }, [router.pathname]);
  console.log(activetab, "activetab");
  const technologiesImg = useMemo(() => {
    if (activetab === "full_stack") {
      return technoimg;
    } else if (activetab === "front_end") {
      return FrontendTechImg;
    } else if (activetab === "back_end") {
      return BackendTechImg;
    } else if (activetab === "mobile") {
      return MobileTechImg;
    }
  });
  return (
    <div className=" d-flex justify-content-center mt-5 pt-xxl-5 pb-md-5 pb-3 pt-5">
      <div className="col-xxl-10 col-11 mt-xxl-5">
        <div className=" d-flex justify-content-center mt-lg-5">
          <div className=" d-lg-flex justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="mt-5 ps-xxl-5">
                <div className="DM_Serif_Text titlepayment">
                  Technologies Taught
                </div>
                <div className="open_sans d-flex justify-content-center mt-3 color_969696 fs_15 me-3">
                  Embark on an enriching journey that equips you with the skills
                  to craft captivating web experiences. From sculpting
                  responsive layouts to amplifying interactivity, our curriculum
                  encompasses the tools needed to build dynamic full stack
                  solutions. With every module, you'll unlock a new dimension of
                  your creative potential. Explore the boundless possibilities
                  that arise from mastering these transformative technologies.
                </div>
                <button className="enrollbtn px-4 py-2 mt-5">Learn Now</button>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
              <div className=" ">
                <img className=" w-100" src={technologiesImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologiesTaught;
