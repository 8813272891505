import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import GoToTop from "../components/helper/go-to-top";

const ManualTesting = () => {
  const [isHomeClicked, setIsHomeClicked] = useState(false);

  const sectionRefs = {
    Curriculum: useRef(null),
    ContactUs: useRef(null),
    DwnldSyllabus: useRef(null),
    PaymentCards: useRef(null),
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isHomeClicked]);


  return (
    <div className="">
      <Navbar
        isActiveTab={isHomeClicked}
        setIsActiveTab={setIsHomeClicked}
        scrollToSection={scrollToSection}
      />
      <div className="p-5 m-lg-5">
        <iframe
          src={"https://ff-e-commerce.s3.ap-south-1.amazonaws.com/Almnd+Course+plan.pdf"}
          width="100%"
          height="720px"
          title="PDF Viewer"
        ></iframe>
      </div>
      <Footer scrollToSection={scrollToSection} />
      <GoToTop />
    </div>
  );
};

export default ManualTesting;
