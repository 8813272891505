import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessModal from "./SuccessModal";
import axios from "axios";
import Select, { components, DropdownIndicatorProps } from "react-select";
import SuccessToast from "../SuccessToast";
import ErrorToast from "../ErrorToast";
import "../style.css";
import { toast } from "react-toastify";
import closeIcon from '../../assets/icons/cancel-icon.svg'
import dropdownIcon from '../../assets/icons/caret-down (1) 1.png'
import dropdownIconNew from '../../assets/icons/caret-down-solid.svg'
import dropDownSvgIcon from '../../assets/Vector.svg'

const EnrollNowModal = ({
  EnrollModalShow,
  handleEnrollModalClose,
  title = "",
  description,
  subsection = false,
  title_color = "",
  selectedCourse = "",
  is_from_testing
}) => {
  const colourStylesRow = {
    dropdownIndicator: styles => ({
      ...styles,
      color: '#FF2852',
      fontWeight: 800,
    })
  }

  const CustomDropdownIndicator = () => {
    return <img src={dropDownSvgIcon} alt="Custom Dropdown Icon" className='dropDownSvgIcon' />;
  };

  const options = [
    { label: "Prepaid", value: "pre_paid" },
    { label: "Postpaid", value: "post_paid" },
  ];

  const courseOptions = [
    { value: "full_stack", label: "Full-Stack" },
    { value: "front_end", label: "Front-End" },
    { value: "back_end", label: "Back-End" },
    { value: "mobile", label: "Mobile Application" },
    { value: 'graphic_design', label: "Graphic Design" },
    { value: 'manual_testing', label: "Manual testing" }
  ];

  const [selectedOption, setSelectedOption] = useState('full_stack');
  const handleSelectOption = (option) => {
    let filtered = courseOptions.filter((item) => item.label === option)
    console.log("selected option", filtered[0].value);
    setSelectedOption(filtered[0].value);
  };

  const phoneRegExp = /^[6,7,8,9][0-9]{0,9}$/;


  const [successMessageShow, setSuccessMessageShow] = useState(false);

  const [error, setError] = useState("");
  const [otpToken, setOtpToken] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isGetOtpClicked, setIsGetOtpClicked] = useState(false);

  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);

  useEffect(() => {
    let countdownInterval;
    if (isGetOtpClicked && timerSeconds > 0) {
      countdownInterval = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

    } else if (timerSeconds === 0 && isGetOtpClicked) {
      clearInterval(countdownInterval);
      setIsResendEnabled(true);
    }
    return () => clearInterval(countdownInterval);
  }, [isGetOtpClicked, timerSeconds]);

  useEffect(() => {
    if (is_from_testing) {
      console.log("trueee");
      setSelectedOption('manual_testing')
    } else {
      setSelectedOption('full_stack')
    }
  }, [is_from_testing])



  const EnrollApi = (values) => {
    console.log(selectedOption, "selected course");
    axios
      .post("https://api.almnd.in/api/enroll-now-create", {
        name: values?.name,
        email_address: values?.email,
        phone_number: values?.phone,
        course_type: values?.payment,
        selected_course: selectedCourse ? selectedCourse : selectedOption
      })
      .then((result) => {
        console.log(result.data);
        // SuccessToast(result.data?.message);
        setSuccessMessageShow(true);

        // handleSuccessMessageShow();
        setError("");
      })
      .catch((error) => {
        console.log(error.response.data.error);
        ErrorToast(error.response.data.message);
        setError(error.response.data.error);
      });
  };

  // const getOtpApi = (values) => {
  //   setIsGetOtpClicked(true);
  //   setIsResendEnabled(false);
  //   setTimerSeconds(30);
  //   axios
  //     .post("https://api.almnd.in/api/auth/send-otp", {
  //       phone_number: values?.phone,
  //       country_code: values?.country_code,
  //     })
  //     .then((result) => {
  //       console.log(result.data, "otp");
  //       SuccessToast(result.data?.message);
  //       setSuccessMessageShow(false);
  //       // handleSuccessMessageShow();
  //       setOtpToken(result.data?.data?.otp_token);
  //       setIsOtpVerified(false);
  //       setError("");
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.error);
  //       ErrorToast(error.response.data.message);
  //       setError(error.response.data.error);
  //     });
  // };
  // const handleVerifyOtp = () => {
  //   verifyOtpApi(formik.values);
  // };

  // const verifyOtpApi = (values) => {
  //   axios
  //     .post("https://api.almnd.in/api/auth/verify-otp", {
  //       otp: values?.otp,
  //       otp_token: otpToken,
  //     })
  //     .then((result) => {
  //       console.log(result.data, "otp");
  //       SuccessToast(result.data?.message);
  //       setSuccessMessageShow(false);
  //       setIsOtpVerified(true);
  //       setError("");
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.error);
  //       ErrorToast(error.response.data.message);
  //       setError(error.response.data.error);
  //     });
  // };

  // const resendOtpApi = (values) => {
  //   axios
  //     .post("https://api.almnd.in/api/auth/resend-otp", {
  //       otp_token: otpToken,
  //     })
  //     .then((result) => {
  //       console.log(result.data, "otp");
  //       SuccessToast(result.data?.message);
  //       setSuccessMessageShow(false);
  //       setIsOtpVerified(false);
  //       setError("");
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.error);
  //       ErrorToast(error.response.data.message);
  //       setError(error.response.data.error);
  //     });
  // };

  // const resendOtpApi = (values) => {
  //   axios
  //     .post("https://api.almnd.in/api/auth/resend-otp", {
  //       otp_token: otpToken, // Use the existing OTP token
  //     })
  //     .then((result) => {
  //       console.log(result.data, "otp");
  //       SuccessToast(result.data?.message);
  //       setSuccessMessageShow(false);
  //       setIsOtpVerified(false);
  //       setError("");

  //       // Capture the new OTP token from the response
  //       const newOtpToken = result.data?.data?.otp_token;
  //       setOtpToken(newOtpToken); // Update the OTP token in state
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data.error);
  //       ErrorToast(error.response.data.message);
  //       setError(error.response.data.error);
  //     });
  // };





  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("!Required"),
      email: Yup.string().required("!Required"),
      phone: Yup.string()
        .required("!Required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Minimum requires 10 numbers")
        .max(10, "Maximum requires 10 numbers only"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values, "formikvalues");
      EnrollApi(values);
      resetForm();
      handleEnrollModalClose();
      // handleSuccessMessageShow();
      // handleModalClose();
      setError(null);

    },
  });
  const handleModalClose = () => {
    formik.resetForm();
    handleEnrollModalClose()
    // setIsGetOtpClicked(false);
    // setTimerSeconds(30);
    // setIsResendEnabled(false);
    // setIsOtpVerified(false);
  };
  const [isFormValid, setIsFormValid] = useState(false);
  // useEffect(() => {
  //   setIsFormValid(formik.isValid && formik.dirty);
  // }, [formik.isValid, formik.dirty]);

  // const startTimer = () => {
  //   setIsGetOtpClicked(true);
  //   setTimerSeconds(30);
  //   setIsResendEnabled(false);

  //   const countdownInterval = setInterval(() => {
  //     setTimerSeconds((prevSeconds) => prevSeconds - 1);
  //   }, 1000);
  //   setTimeout(() => {
  //     clearInterval(countdownInterval);
  //     setIsResendEnabled(true);
  //   }, 30000);
  // };

  return (
    <>
      <SuccessModal
        successMessageShow={successMessageShow}
        handleSuccessMessageClose={() => setSuccessMessageShow(false)}
        title={`${title === "Curriculum"
          ? "Syllabus downloaded successfully"
          : title === "Enroll now" ? "Enrolled Successfully" : title === "Apply Now" ? "Applied Successfully" : title === "Workshop" ? "Joined to workshop successfully" : ""
          }`}
      />
      <Modal
        centered
        show={EnrollModalShow}
        dialogClassName="appproved_form_container"
        contentClassName="border_radius_10px custom-modal-content"
        backdropClassName="create_form_modal_backdrop"
        backdrop={handleModalClose}
        keyboard={true}
        onHide={handleModalClose}
      >
        <Modal.Body className="px-sm-5  mx-sm-3 py-5">
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className={`titleheading DM_Serif_Text fs_20 ${title_color}`}>
              {title}
            </div>
            <div> <img src={closeIcon} alt="" onClick={() => handleModalClose()} className="Enroll_Modal_Closeicon" /> </div>
          </div>
          <div className="Enroll_Modal_Title ">{description}</div>
          <div className="mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div>
                <lable
                  className={`fs_13 fw_600 open_sans ${formik.touched.name && formik.errors.name
                    ? " errorcolor"
                    : ""
                    }`}
                >
                  Name
                </lable>
                <input
                  className={`w-100 inputfields ps-2 ${formik.touched.name && formik.errors.name
                    ? "inputfieldserror"
                    : ""
                    }`}
                  type="text"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="mt-4">
                <lable
                  className={`fs_13 fw_600 open_sans ${formik.touched.email && formik.errors.email
                    ? " errorcolor"
                    : ""
                    }`}
                >
                  Email Address
                </lable>
                <input
                  className={`w-100 inputfields ps-2 ${formik.touched.email && formik.errors.email
                    ? "inputfieldserror"
                    : ""
                    }`}
                  type="email"
                  placeholder="Enter your email address"
                  id="email"
                  email="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <lable
                  className={`fs_13 fw_600 open_sans ${formik.touched.phone && formik.errors.phone
                    ? " errorcolor"
                    : ""
                    }`}
                >
                  Phone Number
                </lable>
                <div className="position-relative">
                  <input
                    className={`w-100 inputfields ps-2 ${formik.touched.phone && formik.errors.phone
                      ? "inputfieldserror"
                      : ""
                      }`}
                    type="text"
                    maxlength="10"
                    pattern="\d{10}"
                    placeholder="Enter your phone number"
                    id="phone"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="errorcolor open_sans ps-2">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
              {!selectedCourse ?
                <div className="mt-4">
                  <lable
                    className={`fs_13 fw_600 open_sans`}
                  >
                    Select Course
                  </lable>
                  <div className="position-relative">
                    <select className={`w-100 inputfields ps-2`} onChange={(e) => handleSelectOption(e.target.value)} >
                      <option>{courseOptions.filter((item) => item.value === selectedOption)[0].label}</option>
                      {courseOptions.map((option, index) => (
                        <option key={index} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                : ""}
              <div>{error}</div>
              {title === "Free Workshop" ?
                <div className="mt-5">
                  <button
                    type="submit"
                    className={`w-100 px-5 p-2 ${!formik.dirty || !formik.isValid ? "submit_inactive_btn" : "submitbtn"}  `}
                    disabled={!formik?.dirty || !formik?.isValid}
                  >SUBMIT
                  </button>
                </div>
                :
                <div className="mt-5">
                  <button
                    type="submit"
                    className={`w-100 px-5 p-2 ${!formik.dirty || !formik.isValid ? "inactivebtn" : "submitbtn"}  `}
                    disabled={!formik?.dirty || !formik?.isValid}
                  >{title === "Apply Now" ? "Apply Now" : "Enroll now"}
                  </button>
                </div>
              }
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnrollNowModal;
