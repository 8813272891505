import React, { useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Contactus from "../../components/ContactUs/contactus";
import FrontEntDevCurriculum from "../../components/Curriculum/front-end-curriculum";
import WorkShopCurriculum from "../../components/Curriculum/work-shop-curriculum";
import GoToTop from "../../components/helper/go-to-top";
const WorkShop = () => {
  const sectionRefs = {
    Curriculum: useRef(null),
    ContactUs: useRef(null),
    DwnldSyllabus: useRef(null),
    PaymentCards: useRef(null),
    // Overview: useRef(null),
  };

  const scrollToSection = (sectionId) => {
    const sectionRef = sectionRefs[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Navbar scrollToSection={scrollToSection} />
      <WorkShopCurriculum/>
      <Footer scrollToSection={scrollToSection} />
      <GoToTop/>
    </div>
  );
};

export default WorkShop;
